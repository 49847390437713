<template>
  <div>
    <div v-show='modeType==0'>
    <div class='row d-flex justify-content-between align-items-center' style='margin:20px 20px 0px'>
      <div style='font-size:20px' class='d-flex'>分公司/部門設定
      </div>
        <div class="align-right">
          <button type="button" @click="handleNew()"  class="btn btn-fill btn-large btn-info" v-if="checkScope(['organization:c'])" >新增公司</button>
      </div>
    </div>
      <div class="row">
        <div class="col-sm-12">
          <el-table :data="tableData"  @sort-change="changeSort" >
            <el-table-column prop="name"
                             label="公司名稱">
            </el-table-column>
            <el-table-column prop="departmentCount"
                             label="部門數量">
              <div class="td-actions" slot-scope="props" >
                {{props.row.departments.length}}
              </div>
            </el-table-column>

            <el-table-column prop="tax_id_no"
                             label="統一編號">
            </el-table-column>
            <el-table-column prop="created_at" :formatter="getCreateDate"
                  label="新增日期">
            </el-table-column>
            <el-table-column
              label="功能"
              min-width="150%"
              >
              <div class="" slot-scope="props">
                <button class="btn btn-secondary btn-fill btn-xs" style='margin-right:10px'
                  @click="handleCompanyEdit(props.row)">
                   編輯公司資訊
                </button>

                <button class="btn btn-secondary btn-fill btn-xs"
                  @click="handleDepartmentEdit(props.row)" v-if="checkScope(['organization:u'])">
                   編輯部門
                </button>
              </div>
            </el-table-column>
          </el-table>
        </div>
      </div>
        <div slot="footer" class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
          <div class="">
            <p class="card-category"></p>
          </div>
          <l-pagination class="pagination-no-border"
                      v-model="pagination.currentPage"
                      :per-page="pagination.perPage"
                      @input='handleIndexChange'
                      :total="pagination.total" />
        </div>
    </div>
    <div v-if='modeType==1'>
    <div class='row d-flex justify-content-between align-items-center' style='margin:20px 20px 0px'>
      <div style='font-size:20px'>{{getTitle()}}</div>
        <div class="align-right">
          <a href="#" @click.prevent="modeType=0;editCancel()" class="" style='margin-right: 40px;font-size:14px;' >&lt;&lt;&lt;&nbsp;返回列表</a>
          <button type="button" @click="edit()" v-show='!is_edit && !is_new' class="btn btn-fill btn-info" v-if="checkScope(['organization:u'])"  >編輯</button>
          <button type="button" @click="editCancel()" v-show='is_edit && form.id' class="btn btn-fill btn-info" style='margin-right: 10px;'>取消</button>
          <button type="submit" @click.prevent="validateCompany" v-show='is_edit' class="btn btn-fill btn-info" >儲存</button>
      </div>
    </div>
      <div class="col-sm-12 row" style="margin-top:40px">
        <div class="col-sm-6 " style='padding:0'>
          <div style='font-size:18px'>公司資訊</div>
          <hr>
          <fg-input label="公司名稱"
                    type="text"
                    name="name"
                    data-vv-as="公司名稱"
                    class=''
                    v-model="form.name"   
                    v-validate="{ required: true }"
                    :error="getError('name')"
                    v-bind:disabled="!is_edit"
                    required>
          </fg-input>
          <fg-input label="統一編號"
                type="text"
                name="tax_id_no"
                data-vv-as="統一編號"
                class=''
                v-model="form.tax_id_no"   
                v-validate="{ required: true }"
                :error="getError('tax_id_no')"
                v-bind:disabled="!is_edit"
                required>
            </fg-input>
            <fg-input label="服務內容文字" :error="getError(`content`)" required>
              <el-input type="textarea" name="content" v-model="form.note" rows="8"  data-vv-as="服務內容文字"  v-validate="'required|max:6800'" v-bind:disabled="!is_edit" ></el-input>
            </fg-input>
            <a href="#" @click.prevent="handleCompanyDelete(form.id)" class="d-flex align-right" style='float: right;font-size:14px;border-bottom: 1px solid currentColor;' v-if="checkScope(['organization:d']) && form.id"  >刪除公司</a>
        </div>
        <div class="col-sm-6" v-show="form.id">
          <div style='font-size:18px'>部門</div>
          <hr>
          <div v-for="i in form.departments" class="select-default" :value="i.id"  :key="i.id"  >
            <label style='text-transform:none;'>{{i.name}}</label>
          </div>

        </div>

      </div>
    </div>
    <div v-if='modeType==2'>
    <div class='row d-flex justify-content-between align-items-center' style='margin:20px 20px 0px'>
      <div style='font-size:20px'>編輯部門</div>
        <div class="align-right">
          <a href="#" @click.prevent="modeType=0;editCancel()" class="" style='margin-right: 40px;font-size:14px;' >&lt;&lt;&lt;&nbsp;返回列表</a>
      </div>
    </div>
      <div class="col-sm-12 row" >
        <div class="col-sm-8 " style='padding:0' v-if="checkScope(['organization:c'])" >
          <div style='display:flex;margin: 20px 20px 0px;height:40px;gap:20px;'>
            <h6 style='align-self:center;margin-left:0;'>部門名稱</h6>
            <fg-input 
                  type="text"
                  data-vv-as=""
                  class='col-sm-5'
                  v-model="add_department"   
                  >
              </fg-input>
              <button type="submit" @click.prevent="validateDepartment" class="btn btn-info " >增加部門</button>
          </div>
        </div>
        <div class="col-sm-12 " style="margin-top:60px;">
          <div style='font-size:18px'>部門列表</div>
          <el-table :data="form.departments"  >
            <el-table-column label="部門名稱" min-width="150%">
              <template slot-scope="scope">
                <el-input v-model="scope.row.name" :disabled="!scope.row.edited"></el-input>
              </template>
            </el-table-column>
            <el-table-column
              label="功能"
              >
              <template class="" slot-scope="props">

                <button class="btn btn-secondary btn-fill btn-xs" style='margin-right:10px'
                  @click="handleDepartmentRowEdit(props.$index)" v-show="!props.row.edited" v-bind:disabled="form.departments.find((el) => el.edited)">
                   修改名稱
                </button>

                <button class="btn btn-secondary btn-fill btn-xs" style='margin-right:10px'
                  @click="handleDepartmentRowEditCancel(props.$index)" v-show="props.row.edited">
                   取消
                </button>

                <button class="btn btn-secondary btn-fill btn-xs " style='margin-right:10px'
                  @click="handleDepartmentRowEditSave(props.$index)" v-show="props.row.edited">
                   儲存
                </button>

                <button class="btn btn-secondary btn-fill btn-xs" v-if="checkScope(['organization:d'])"
                  @click="handleDepartmentDelete(props.row.id)" v-show="!props.row.edited"  v-bind:disabled="form.departments.find((el) => el.edited)">
                  刪除
                </button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
 /* eslint-disable */ 
  import { Dialog, Table, TableColumn, MessageBox, Select, Option } from 'element-ui'
  import { Pagination as LPagination, ImageUpload,  Switch as LSwitch } from 'src/components/index'
  import lib from '../../../lib'
  import tools from '../../../tools'
  import swal from 'sweetalert2/dist/sweetalert2.js'
  import moment from 'moment'
  export default {
    components: {
      [Select.name]: Select,
      [Option.name]: Option,
      [Dialog.name]: Dialog,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      LSwitch,
      LPagination,
      ImageUpload,

    },
    data () {
      return {
        pagination: {
          perPage: 20,
          currentPage: 1,
          total: 0
        },
        form:{
          id: null,
          image: null,
          title: null,
          content: null,
        },
        formDeparment:{

        },
        searchForm:{
        },
        roles:[
          {role_id: 2, text: ''}

        ],
        query: {},
        modeType: 0,
        is_edit_page: false,
        is_edit: false,
        is_new: false,
        add_department: null,
        tableData: []
      }
    },
   async mounted () {
      await this.getData()
      this.is_edit_page = false
    },
    watch:{
    },
    computed: {
      getPasswordRule(){
        let rule = {}
        if (!this.form.id){
          rule.required = true
        }
        rule.regex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/
        return rule
      }
    },
    methods: {
      getTitle (){
        return (!this.form.id)?'新增分公司':'編輯分公司'
      },
      getError (fieldName) {
        return this.errors.first(fieldName)
      },
      getEnableText(row){
        var is_enable = row.is_enable
        return (is_enable)?'ON':'OFF'
      },
      getCreateDate(row){
        var date1 = new Date(row.created_at)
        return moment(date1).format('YYYY-MM-DD')
      },
      getSubmitText(){
        return (!this.form.id)?'新增管理者':'儲存管理者'
      },
      validateCompany () {
        var _this = this
        this.$validator.validateAll().then(isValid => {
          if (!isValid) return;
          if (!this.form.id){
            _this.createCompany()
          } else{
            _this.updateCompany()
          }
        })
      },
      async validateDepartment (){
        if (!this.add_department) {
          swal('請填入 部門名稱', '', 'error')
          return
        }

        var result = await lib.createDepartment(this.form.id, {name: this.add_department})
        if (result.code == 0){
          this.add_department = null
          var result = await lib.getDepartmentList(this.form.id, {page:1, limit:1000})
          if (result.code == 0){
            this.form.departments = result.data
            this.origin = JSON.parse(JSON.stringify(this.form))
          }
         
          swal('已更新', '', 'success')
        }else {
          swal('更新失敗', '', 'error')
        }
       
      },
      handleImageUpload(result, data){
        if (result){
          this.form.image = data
        }else{
          swal('上傳失敗', data, 'error')
        }
      },
      edit (){
        this.is_edit = true
      },
      editCancel (){
        this.is_edit = false
        if (this.origin) this.form = JSON.parse(JSON.stringify(this.origin))
      },
      changeMode (mode) {

      },
      changeSort (val) {
           console.log(val)
      },
      async handleIndexChange() {
        await this.getData()
      },
      async handleNew () {
        await this.clearForm()
        this.modeType = 1
        this.is_edit = true
        this.form = {}
        this.$validator.errors.clear()
      },
      async handleCompanyEdit (row) {
        await this.clearForm()
        this.modeType = 1
        this.$validator.errors.clear()
        this.origin = JSON.parse(JSON.stringify(row))
        this.form = JSON.parse(JSON.stringify(row))
      },
      async handleDepartmentEdit (row) {
        await this.clearForm()
        this.modeType = 2
        row.departments.map((el) => delete el.edited)
        this.origin = JSON.parse(JSON.stringify(row))
        this.form = row
      },
      async handleDepartmentRowEdit (idx) {
        this.origin = JSON.parse(JSON.stringify(this.form))
        this.$set(this.form.departments[idx], 'edited', true)
      },
      async handleDepartmentRowEditCancel (idx) {
        this.form = JSON.parse(JSON.stringify(this.origin))
        this.$set(this.form.departments[idx], 'edited', false)
      },
      async handleDepartmentRowEditSave (idx) {
          var result = await lib.updateDepartment(this.form.id, this.form.departments[idx].id, this.form.departments[idx])
          if (result.code == 0){
            this.origin = JSON.parse(JSON.stringify(this.form))
            this.form.departments[idx].edited = false
            swal('已更新', '', 'success')
          }else {
            swal('更新部門失敗', '', 'error')
          }
      },
      async handleDepartmentDelete (id) {
        var confirm = await tools.confirmDialog("請確認要刪除部門?");
          if (confirm){
            var result = await lib.deleteDepartment(this.form.id, id)
            if (result.code == 0){
              var result = await lib.getDepartmentList(this.form.id, {page:1, limit:1000})
              if (result.code == 0){
                this.form.departments = result.data
                this.origin = JSON.parse(JSON.stringify(this.form))
              }  
            swal('已刪除部門', '', 'success')
          }else {
            let msg = ''
            if (result.code == 5) msg = '請先刪除此部門所有管理者'
            swal('刪除部門失敗', msg, 'error')
          }
        }
      },  
      async getData () {
        var result = await lib.getCompanyList({page: this.pagination.currentPage})
        if (result.code == 0){
          var data = result.data
          this.tableData = data
          this.pagination.total = result.pages.total
        }
      },
      async clearForm(){
        var _this = this
        return new Promise(function(resolve, reject){  
            _this.form.id = ''
          resolve()
        })
      },
      async createCompany(){
        var result = await lib.createCompany(this.form);
        if (result.code == 0){
          await this.getData()
          this.modeType = 0
          this.is_edit_page = false
          swal('新增成功', '', 'success')
        }else {
          swal('新增失敗', result.message, 'error')
        }
      },
      async updateCompany(){
        var result = await lib.updateCompany(this.form.id, this.form);
        if (result.code == 0){
          await this.getData()
          this.is_edit = false
          this.is_edit_page = false
          swal('已更新', '', 'success')
        }else {
          swal('更新失敗', '', 'error')
        }
      },
      async handleCompanyDelete(id, porp){
        var confirm = await tools.confirmDialog("請確認要刪除?");
          if (confirm){
            var result = await lib.deleteCompany(id)
            if (result.code == 0){
              this.pagination.currentPage = 1
              this.is_edit = false
              this.is_edit_page = false
              this.modeType = 0
              await this.getData()
              swal('已刪除', '', 'success')
            }else{
              let msg = ''
              if (result.code == 5) msg = '請先刪除此公司所有部門'
              swal('刪除失敗', msg, 'error')
            }
        }
      }
    }
  }
</script>
<style>
.toolsBar{
  justify-content: space-between;
  display: flex;
}

.leftTools{
  display: flex;
}

.leftTools > *{
  padding-right: 10px;
}
</style>
