<template>
  <div class="table-container">
    <div class='d-flex justify-content-between mb-2'>
      <div class="d-flex">
        <fg-input
          class="my-auto ml-1 mr-2"
          v-model="query.keyword"
          type="text">
        </fg-input>

        <l-button fill round size="sm" type="primary" class="m-auto" @click="resetPage()">Search</l-button>
      </div>
      <div class="align-right my-auto">
        <button type="button" @click="handleDone()" class="btn btn-round btn-large btn-primary my-auto">Done</button>
      </div>
    </div>
    
    <!-- <div class="d-flex">
      <h5 class="my-auto">Group</h5>
      <b-button variant="primary" @click="handleAdd()" class="my-auto mx-3">Add</b-button>
    </div> -->
    <!-- @row-clicked="test" -->
    <div class="row">
      <b-editable-table
        v-show="hasItem()"
        disableDefaultEdit
        :rowUpdate="rowUpdate"
        :editMode="'row'"
        bordered
        class="editable-table col-12"
        v-model="items"
        :fields="fields"
      >
        <!-- <template #cell(isActive)="data">
          <span v-if="data.value">Yes</span>
          <span v-else>No</span>
        </template>
        <template #cell(admin)="data">
          <span v-if="data.value">Yes</span>
          <span v-else>No</span>
        </template>
        <template #cell(status)="data">
          <span v-if="data.value">Yes</span>
          <span v-else>No</span>
        </template> -->
        <template #cell(edit)="data">
          <!-- <div v-if="data.isEdit">
            <BIconX class="edit-icon" @click="handleSubmit(data, false)"></BIconX>
            <BIconCheck
              class="edit-icon"
              @click="handleSubmit(data, true)"
            ></BIconCheck>
          </div>
          <BIconPencil
            v-else
            class="edit-icon"
            @click="handleEdit(data, true)"
          ></BIconPencil> -->

          <BIconPlusCircle
            v-if="!isJoinedWith(data.id)"
            class="edit-icon mx-auto"
            @click="handleAdd(data)"
          ></BIconPlusCircle>
        </template>
      </b-editable-table>
    </div>
    <div slot="footer" class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap mt-3">
      <div class="">
        <p class="card-category"></p>
      </div>
      <l-pagination class="pagination-no-border"
        v-model="pagination.currentPage"
        :isMore="(PaginationToken != null)"
        :per-page="pagination.perPage"
        :total="pagination.total"
        @input='handleIndexChange'
        @loadMore="getData(true)"/>
    </div>
    <!-- <pre>
      {{ items }}
    </pre> -->
  </div>
</template>

<script>
import BEditableTable from "bootstrap-vue-editable-table"
import { Pagination as LPagination } from 'src/components/index'
import lib from '../../../lib.js'
import Vue from "vue"
import sweetAlert from 'sweetalert2/dist/sweetalert2.js'
import {
  BIconTrash,
  BIconPencil,
  BIconX,
  BIconCheck,
  BButton,
  BIconPlusCircle
} from "bootstrap-vue";
export default {
  props: ['group'],
  components: {
    BEditableTable,
    BIconX,
    BIconTrash,
    BIconPencil,
    BIconCheck,
    BButton,
    BIconPlusCircle,
    LPagination
  },
  data() {
    return {
      pagination: {
        perPage: 15,
        currentPage: 1,
        total: 0
      },
      PaginationToken: null,
      query: {
        keyword: '',
      },
      memberIds: [],
      search_text: null,
      fields: [
        {
          key: "id",
          label: "No",
          type: "text",
          editable: false,
          placeholder: "Group Name",
          class: "col-1"
        },
        {
          key: "displayName",
          label: "Name",
          type: "text",
          editable: false,
          placeholder: "Group Name",
          class: "col-2"
        },
        {
          key: "hospital",
          label: "Hospital",
          type: "text",
          editable: false,
          placeholder: "Group Name",
          class: "col-2"
        },
        {
          key: "department",
          label: "Department",
          type: "text",
          editable: false,
          placeholder: "Group Name",
          class: "col-2"
        },
        {
          key: "email",
          label: "Email",
          type: "text",
          editable: false,
          placeholder: "Group Name",
          class: "col-4"
        },
        { key: "edit", label: "Add", class: "col-1 text-center" },
      ],
      items: [],
      rowUpdate: {},
    };
  },
  async mounted () {
    for (var user of this.group.users) {
      this.memberIds.push(user.id)
    }
    await this.getData()
  },
  methods: {
    async addMember(userId) {
      var result = await lib.addMember(this.group.id, userId)
      if (result.status == Vue.Constants.HttpStatus.Success) {
        var data = result.data
        
        this.memberIds.push(userId)
      } else {
        if (result.data.message) {
          sweetAlert.fire({
            type: 'error',
            text: result.data.message
          })
        }
      }
    },
    isJoinedWith(userId) {
      for (var memberId of this.memberIds) {
        if (userId == memberId) {
          return true
        }
      }
      return false
    },
    async resetPage() {
      this.pagination.currentPage = 1
      await this.getData()
    },
    async getData() {
      console.log('getData')
      let query = {}
      query.page = this.pagination.currentPage - 1
      query.keyword = this.query.keyword
      var result = await lib.getUserList(query)
      if (result.status == Vue.Constants.HttpStatus.Success) {
        var data = result.data

        this.pagination.currentPage = data.data.curPage + 1
        this.pagination.total = data.data.totalCount
        this.items = data.data.users
      }
    },
    hasItem() {
      return this.items.length != 0
    },
    async handleIndexChange() {
      await this.getData()
    },
    handleDone() {
      this.$emit('back')
    },
    handleSubmit(data, update) {
      this.rowUpdate = {
        edit: false,
        id: data.id,
        action: update ? "update" : "cancel",
      };
    },
    handleEdit(data) {
      this.rowUpdate = { edit: true, id: data.id };
    },
    handleDelete(data) {
      this.rowUpdate = { id: data.id, action: "delete" };
    },
    validateName(value) {
      if (value === '') {
        return {
          valid: false,
          errorMessage: 'Please enter name'
        }
      }
      return {valid: true};
    },
    handleAdd(data) {
      this.addMember(data.id)
    }
  },
};
</script>

<style>
.table-container {
  margin: 10px;
}

table.editable-table {
  margin-top: 10px;
}

table.editable-table td {
  vertical-align: middle;
}

.editable-table .data-cell {
  padding: 8px;
  vertical-align: middle;
}

.editable-table .custom-checkbox {
  width: 50px;
}

.remove-icon {
  color: red;
  cursor: pointer;
  font-size: 20px;
}

.edit-icon {
  color: rgb(4, 83, 158);
  cursor: pointer;
  font-size: 20px;
}

/* .name-col {
  width: 120px;
} */

/* .department-col {
  width: 150px;
}

.age-col {
  width: 100px;
}

.date-col {
  width: 200px;
}

.is-active-col {
  width: 100px;
} */
</style>