<template>
  <div>

    <div v-if='!targetData'>
      <div class="row">
        <div class="col-sm-12">
          <!-- highlight-current-row
          @current-change="selectionRowsChange" -->
          <el-table :data="infos" @row-click="handleRowChange" :cell-style="cellStyle">

            <el-table-column prop="input_timestamp" label="Date">
            </el-table-column>

            <el-table-column prop="full_name" label="Name">
            </el-table-column>

            <el-table-column prop="occupational_history" label="Occupational History">
            </el-table-column>

            <el-table-column prop="travel_history" label="Travel History">
            </el-table-column>

            <!-- <el-table-column prop="funciton" label="" width="64px" class="mx-0 px-0">
              <l-button slot-scope="props" outline size="sm" type="info" @click="view(props.row)" class="m-auto px-2">view</l-button>
            </el-table-column>

            <el-table-column prop="funciton" label="" width="60px" class="mx-0">
              <l-button slot-scope="props" outline size="sm" type="info" @click="edit(props.row)" class="m-auto px-2">edit</l-button>
            </el-table-column> -->

            <!-- <el-table-column prop="status" :formatter="getStatusText" label="Status">
            </el-table-column> -->

          </el-table>
        </div>
      </div>
        
      <div slot="footer" class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap mt-3">
        <div class="">
          <p class="card-category"></p>
        </div>
        <l-pagination class="pagination-no-border"
          v-model="pagination.currentPage"
          :isMore="(PaginationToken != null)"
          :per-page="pagination.perPage"
          :total="pagination.total"
          @input='handleIndexChange'
          @loadMore="getInfos()"/>
      </div>
    </div>

    <GeneralInfoEdit v-if='targetData' :data='targetData' @back='backToList()' @save='backToList()' class="mt-3" />
    <!-- <GeneralInfo v-if='!isEditing && targetData' :data='targetData' @back='backToList()' class="mt-3" /> -->

  </div>
</template>
<script>
 /* eslint-disable */ 

import { Table, TableColumn } from 'element-ui'
import { Pagination as LPagination } from 'src/components/index'
import lib from '../../../lib'
import Vue from "vue"
import GeneralInfo from './GeneralInfo'
import GeneralInfoEdit from './GeneralInfoEdit'


export default {
  props: ['data'],
  data () {
    return {
      infos: null,
      targetData: null,
      pagination: {
        perPage: 10,
        currentPage: 1,
        total: 0
      },
      PaginationToken: null,
      dateInfo: null,
      timelineOffset: 0
    }
  },
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    LPagination,
    GeneralInfo: GeneralInfo,
    GeneralInfoEdit: GeneralInfoEdit
  },
  async mounted() {
    if (this.dateInfo == null) {
      this.dateInfo = lib.getCurrentDateInfo()
      this.timelineOffset = 0
    }
    await this.getInfos()
  },
  methods: {
    cellStyle(row, rowIndex) {
      return 'cursor: pointer;'
    },
    timelinePrevious() {
      this.timelineOffset++
      this.getInfos()
    },
    timelineNext() {
      if (this.timelineOffset != 0) {
        this.timelineOffset--
        this.getInfos()
      }
    },
    async getInfos() {
      if (this.dateInfo) {
        const startDate = lib.getStartDateText(this.dateInfo, this.timelineOffset)
        const endDate = lib.getEndDateText(this.dateInfo, this.timelineOffset)
        var result = await lib.generalInfoListOfUser(this.data.user_id, startDate, endDate, this.pagination.currentPage - 1)
        if (result.status == Vue.Constants.HttpStatus.Success) {
          var data = result.data
          this.infos = data.data

          this.pagination.perPage = data.page_size
          this.pagination.currentPage=  data.page + 1
          this.pagination.total = data.total_size

          var setIndex = new Set()
          const baseDate = new Date(startDate)

          var timelinePoints = data.time_line_points
          if (timelinePoints) {
            for (let i = 0; i < timelinePoints.length; i++) {
              const info = timelinePoints[i]
              var targetDate = new Date(info.current_datetime)
              setIndex.add(lib.getDateDiffInMonth(baseDate, targetDate))
            }
          }
          // console.log('setIndex:', setIndex)
          this.$emit('timelineInfo', {startDate: startDate, endDate: endDate, timelineIndex: setIndex})
        }
      }
    },
    async handleIndexChange() {
      await this.getInfos()
    },
    handleRowChange(row, event, column) {
      this.targetData = row
    },
    // view(data) {
    //   this.targetData = data
    // },
    // edit(data) {
    //   this.targetData = data
    // },
    backToList() {
      this.targetData = null
    }
  }
}
</script>
