<template>
  <div class="table-container">
    <div class="d-flex">
      <h5 class="my-auto">Group</h5>
    </div>

    <div class="row mt-3">
      <fg-input
        class="my-auto px-0 col-4"
        name="groupName"
        data-vv-as="Group Name"
        v-model="form.name"
        placeholder="Group Name"
        type="text"
        v-validate="{ required: true }"
        :error="getError('groupName')"
        >
      </fg-input>
      
      <div class="col-3 d-flex align-items-center">
        <fg-input
          class="my-auto mr-1"
          name="dataSharingRate"
          data-vv-as="Data Sharing Rate"
          v-model="form.dataSharingRate"
          placeholder="Data Sharing Rate"
          type="number"
          v-validate="{ required: true }"
          :error="getError('dataSharingRate')"
          required>
        </fg-input>%
      </div>

      <l-switch class="my-auto" v-model="form.status" on-text="ON" off-text="OFF"></l-switch>

      <!-- <el-select class="select-default px-0 col-2" v-model="statusModel" :close-transition="true">
        <el-option value='0' label="Disabled" />
        <el-option value='1' label="Enabled" />
      </el-select> -->
      <b-button variant="primary" @click.prevent="validate" class="my-auto mx-3">Add</b-button>
    </div>

    <div class="row">
      <!-- @row-clicked="test" -->
      <b-editable-table
        v-show="hasItem()"
        disableDefaultEdit
        :rowUpdate="rowUpdate"
        :editMode="'row'"
        bordered
        class="editable-table col-12"
        v-model="items"
        :fields="fields"
      >
        
        <template #cell(admin)="data">
          <span v-if="data.value">Yes</span>
          <span v-else>No</span>
        </template>
        <template #cell(status)="data">
          <span v-if="data.value">On</span>
          <span v-else>Off</span>
        </template>
        <template #cell(edit)="data">
          <div v-if="data.isEdit">
            <BIconX class="edit-icon" @click="handleSubmit(data, false)"></BIconX>
            <BIconCheck
              class="edit-icon"
              @click="handleSubmit(data, true)"
            ></BIconCheck>
          </div>
          <!-- <BIconPencil
            v-else
            class="edit-icon"
            @click="handleEdit(data, true)"
          ></BIconPencil> -->
        </template>
        <template #cell(delete)="data">
          <BIconTrash v-if="!data.isEdit"
            class="remove-icon mx-auto"
            @click="handleDelete(data)"
          ></BIconTrash>
        </template>
      </b-editable-table>
    </div>
  </div>
</template>

<script>
import { Dialog, Table, TableColumn, MessageBox, Select, Option, DatePicker, Tag, Autocomplete } from 'element-ui'
import { Switch as LSwitch } from 'src/components/index'
import BEditableTable from "bootstrap-vue-editable-table";
import lib from '../../../lib.js'
import Vue from "vue"
import {
  BIconTrash,
  BIconPencil,
  BIconX,
  BIconCheck,
  BButton,
} from "bootstrap-vue";
export default {
  props: ['groups', 'hospitalId'],
  components: {
    BEditableTable,
    BIconX,
    BIconTrash,
    BIconPencil,
    BIconCheck,
    BButton,
    [Select.name]: Select,
    [Option.name]: Option,
    LSwitch
  },
  data() {
    return {
      form: {
        status: false
      },
      fields: [
        {
          key: "name",
          label: "Group Name",
          type: "text",
          editable: true,
          placeholder: "Group Name",
          validate: this.validateName
        },
        {
          key: "dataSharingRate",
          label: "Data Sharing(%)",
          type: "number",
          editable: true,
          placeholder: "Data Sharing",
        },
        {
          key: "members",
          label: "Members",
          type: "number",
          editable: false,
          placeholder: "Members",
          class: "col-1"
        },
        {
          key: "admin",
          label: "Admin",
          type: "checkbox",
          editable: false,
          class: "col-1",
        },
        {
          key: "status",
          label: "Status",
          type: "checkbox",
          editable: true,
          class: "col-1",
        },
        { key: "delete", label: "Remove", class: "col-1 text-center" },
      ],
      items: [],
      rowUpdate: {},
    };
  },
  watch: {
    items: function(val, oldVal) {
      if (!this.rowUpdate.edit) {

      }
    }
  },
  async mounted () {
    this.items = []
    for (var group of this.groups) {
      this.items.push({
        id: group.id,
        name: group.name,
        dataSharingRate: group.dataSharingRate,
        members: 0,
        admin: group.admin,
        status: group.status
      })
    }
  },
  computed: {
    statusModel: {
      get: function() {
          return this.getStatusText(this.form.status)
      },
      set: function(newValue) {
          switch (newValue) {
            case '0':
              this.form.status = false
              break
            case '1':
              this.form.status = true
              break
            default:
              break
          }
      }
    },
  },
  methods: {
    async getHospital() {
      const result = await lib.getHospital(this.hospitalId)
      if (result.status == Vue.Constants.HttpStatus.Success) {
        var hospital = result.data.data
        console.log('hospital:', hospital)
        this.items = []
        for (var group of hospital.groups) {
          this.items.push({
            id: group.id,
            name: group.name,
            dataSharingRate: group.dataSharingRate,
            members: 0,
            admin: group.admin,
            status: group.status
          })
        }
      } else {
        if (result.data.message) {
          sweetAlert.fire({
            type: 'error',
            text: result.data.message
          })
        }
      }
    },
    async createGroup() {
      this.form.hospitalId = this.hospitalId
      const result = await lib.createGroup(this.form)
      if (result.status == Vue.Constants.HttpStatus.Success) {
        console.log('result:', result)

        // const newId = Date.now();
        // this.rowUpdate = {
        //   edit: false,
        //   id: newId,
        //   action: "add",
        //   data: {
        //     id: newId,
        //     name: this.form.name,
        //     dataSharingRate: this.form.dataSharingRate,
        //     members: 0,
        //     admin: false,
        //     status: this.form.status
        //   },
        // };

        this.getHospital()

        this.form.name = null
        this.form.dataSharingRate = null
        this.form.status = false

        // sweetAlert.fire({
        //   type: 'success',
        //   text: result.data.message
        // }).then((result) => {
        //   this.$emit('back')
        // })
      } else {
        if (result.data.message) {
          sweetAlert.fire({
            type: 'error',
            text: result.data.message
          })
        }
      }
    },
    async deleteGroup(id) {
      this.form.hospitalId = this.hospitalId
      const result = await lib.deleteGroup(id)
      if (result.status == Vue.Constants.HttpStatus.Success) {
        console.log('result:', result)

        this.rowUpdate = { id: id, action: "delete" }
      } else {
        if (result.data.message) {
          sweetAlert.fire({
            type: 'error',
            text: result.data.message
          })
        }
      }
    },
    getStatusText(value) {
      return (value) ? "Enabled" : "Disabled"
    },
    hasItem() {
      return this.items.length != 0
    },
    validate () {
      var _this = this
      this.$validator.validateAll().then(isValid => {
        if (!isValid) return;
        console.log('form:', this.form)
        _this.createGroup()
      })
    },
    handleAdd() {
      const newId = Date.now();
      this.rowUpdate = {
        edit: true,
        id: newId,
        action: "add",
        data: {
          id: newId,
          name: '',
          dataSharingRate: 50,
          members: 0,
          admin: false,
          status: true
        },
      };
    },
    handleSubmit(data, update) {
      this.rowUpdate = {
        edit: false,
        id: data.id,
        action: update ? "update" : "cancel",
      };
    },
    handleEdit(data) {
      this.rowUpdate = { edit: true, id: data.id };
    },
    handleDelete(data) {
      this.deleteGroup(data.id)
      // this.rowUpdate = { id: data.id, action: "delete" };
    },
    validateName(value) {
      console.log('validateName, value:', value)
      if (value === '') {
        return {
          valid: false,
          errorMessage: 'Please enter name'
        }
      }
      return {valid: true};
    },
    getError(fieldName) {
      return this.errors.first(fieldName)
    },
  },
};
</script>

<style>
.table-container {
  margin: 10px;
}

table.editable-table {
  margin-top: 10px;
}

table.editable-table td {
  vertical-align: middle;
}

.editable-table .data-cell {
  padding: 8px;
  vertical-align: middle;
}

.editable-table .custom-checkbox {
  width: 50px;
}

.remove-icon {
  color: red;
  cursor: pointer;
  font-size: 20px;
}

.edit-icon {
  color: rgb(4, 83, 158);
  cursor: pointer;
  font-size: 20px;
}

/* .name-col {
  width: 120px;
}

.department-col {
  width: 150px;
}

.age-col {
  width: 100px;
}

.date-col {
  width: 200px;
}

.is-active-col {
  width: 100px;
} */
</style>