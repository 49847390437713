<template>
  <div>
    <div class="row">
      <div class="col-10">
        <div class="row">
          <div class="col-5">
            <p>NO: {{data.sys_id}}</p>
          </div>
          <div class="col-3">
            <p>Case Number: {{data.user_id}}</p>
          </div>
          <div class="col-3">
            <p>ID Number: {{data.identity_number}}</p>
          </div>
        </div>
        <div class="row">
          <div class="col-5">
            <p>Name: {{data.full_name}}</p>
          </div>
          <div class="col-3">
            <p>Birthday: {{data.birthday}}</p>
          </div>
          <div class="col-3">
            <p>Gender: {{data.gender}}</p>
          </div>
        </div>
      </div>
      
      <div class="col-2">
        <div align='right'>
        <l-button size="sm" type="primary" class="mr-3 px-2"  v-b-modal.add-modal>Add</l-button>
        <l-button size="sm" type="primary" @click="share()" class="mr-3 px-2">Share</l-button>
        <l-button size="sm" type="default" outline @click="back()" class="px-2">Back</l-button>
        </div>
      </div>
      <div>
        <b-modal id="add-modal" centered :title="addModalTitle" ok-title="Save">
          <b-form-textarea rows="6" />
        </b-modal>
      </div>
    </div>

    <div class="d-flex justify-content-between mb-0 mt-5">
      <p class="mb-0" style="font-size: 0.8rem;">{{svgStartDate}}</p>
      <p class="mb-0" style="font-size: 0.8rem;">&nbsp;</p>
      <p class="mb-0" style="font-size: 0.8rem;">{{svgEndDate}}</p>
    </div>
    <svg xmlns="http://www.w3.org/2000/svg" :viewBox="svgViewBox" class="mb-5 mt-0">
      <!-- <defs>
        <marker id="startarrow" markerWidth="10" markerHeight="7" refX="10" refY="3.5" orient="auto">
          <polygon @click='test()' points="10 0, 10 7, 0 3.5" fill="black" v-if='true' />
        </marker>
        <marker id="endarrow" markerWidth="10" markerHeight="7" refX="0" refY="3.5" orient="auto" markerUnits="strokeWidth">
          <polygon points="0 0, 10 3.5, 0 7" fill="black" />
        </marker>
      </defs>
      <line x1="10" y1="15" x2="340" y2="15" stroke="#000" stroke-width="1" marker-end="url(#endarrow)" marker-start="url(#startarrow)" /> -->

      <line :x1="svg.arrowWidth" :y1="svg.lineMarginTop" :x2="svg.width - svg.arrowWidth" :y2="svg.lineMarginTop" stroke="#000" stroke-width="1" />
      <polygon :points="svgLeftArrowPoints" fill="black" v-if='svg.rightArrowVisibility' @click="svgLeftArrowClicked()" style="cursor: pointer;" />
      <polygon :points="svgRightArrowPoints" fill="black" v-if='svg.leftArrowVisibility' @click="svgRightArrowClicked()" style="cursor: pointer;" />
      <!-- <polygon @click="test()" style="cursor: pointer;" points="22.5 20, 25 25, 20 25" :fill='isHover ? "green" : "black"' v-if='true'
      @mouseover="mouseover()"
      @mouseout="mouseout()" /> -->
      <!-- <polygon points="22.5 18, 25 23, 20 23" fill="red" v-if='true' /> -->

      <polygon v-for="i in svgIndicator" :key="i" :points="svgIndicatorPoints(i)" fill="red" />

      <!-- <polygon points="42.5 20, 45 25, 40 25" fill="red" v-if='true' /> -->
      <!-- <polygon points="30 0, 30 7, 20 3.5" fill="red" v-if='true' /> -->
    </svg>

    <vue-tabs @tab-change="handleTabChange">
      <v-tab title='General Info' name='generalInfo' >
        <GeneralInfoList ref="GeneralInfoList" :data='this.data' @timelineInfo='timelineInfo($event)' />
      </v-tab>
      <v-tab title='Medical History' name='medicalHistory' >
        <MedicalHistoryList ref="MedicalHistoryList" :data='this.data' @timelineInfo='timelineInfo($event)' />
      </v-tab>
      <v-tab title='Admission Note' name='admissionNote' >
        <AdmissionNoteList ref="AdmissionNoteList" :data='this.data' @timelineInfo='timelineInfo($event)' />
      </v-tab>
      <v-tab title='Progress Note' name='progressNote' >
        <ProgressNoteList ref="ProgressNoteList" :data='this.data' @timelineInfo='timelineInfo($event)' />
      </v-tab>
      <v-tab title='Discharge Note' name='dischargeNote' >
        <DischargeNoteList ref="DischargeNoteList" :data='this.data' @timelineInfo='timelineInfo($event)' />
      </v-tab>
      <v-tab title='Inspection Report' name='inspectionReport' >
        <InspectionReportList ref="InspectionReportList" :data='this.data' @timelineInfo='timelineInfo($event)' />
      </v-tab>
      <v-tab title='Test Report' name='testReport' >
        <TestReportList ref="TestReportList" :data='this.data' @timelineInfo='timelineInfo($event)' />
      </v-tab>
      <v-tab title='Surgery Report' name='surgeryReport' >
        <SurgeryReportList ref="SurgeryReportList" :data='this.data' @timelineInfo='timelineInfo($event)' />
      </v-tab>
    </vue-tabs>

  </div>
</template>
<script>
 /* eslint-disable */ 

  import { BFormTextarea, BModal, VBModal } from 'bootstrap-vue'
  import GeneralInfoList from './GeneralInfoList'
  import MedicalHistoryList from './MedicalHistoryList'
  import AdmissionNoteList from './AdmissionNoteList'
  import ProgressNoteList from './ProgressNoteList'
  import DischargeNoteList from './DischargeNoteList'
  import InspectionReportList from './InspectionReportList'
  import TestReportList from './TestReportList'
  import SurgeryReportList from './SurgeryReportList'


export default {
  props: ['data'],
  data () {
    return {
      currentTabIndex: 0,
      isHover: false,

      svg: {
        width: 350,
        height: 24,
        arrowWidth: 10,
        arrowHeight: 7,
        lineMarginTop: 9,

        leftArrowVisibility: true,
        rightArrowVisibility: true,

        indicatorWidth: 5,
        indicatorMarginTop: 13,
        indicatorCount: 25
      },

      svgStartDate: null,
      svgEndDate: null,
      svgIndicator: null
    }
  },
  components: {
    BFormTextarea,
    BModal,
    GeneralInfoList: GeneralInfoList,
    MedicalHistoryList: MedicalHistoryList,
    AdmissionNoteList: AdmissionNoteList,
    ProgressNoteList: ProgressNoteList,
    DischargeNoteList: DischargeNoteList,
    InspectionReportList: InspectionReportList,
    TestReportList: TestReportList,
    SurgeryReportList: SurgeryReportList
  },
  directives: {
    'b-modal': VBModal
  },
  computed: {
    svgViewBox() {
      return "0 0 " + this.svg.width + " " + this.svg.height
    },
    svgLeftArrowPoints() {
      return "0 " + this.svg.lineMarginTop + ", " + this.svg.arrowWidth + " " + (this.svg.lineMarginTop - (this.svg.arrowHeight / 2.0)) + ", " + this.svg.arrowWidth + " " + (this.svg.lineMarginTop + (this.svg.arrowHeight / 2.0))
    },
    svgRightArrowPoints() {
      return "" + this.svg.width + " " + this.svg.lineMarginTop + ", " + (this.svg.width - this.svg.arrowWidth) + " " + (this.svg.lineMarginTop - (this.svg.arrowHeight / 2.0)) + ", " + (this.svg.width - this.svg.arrowWidth) + " " + (this.svg.lineMarginTop + (this.svg.arrowHeight / 2.0))
    },
    svgLineWidth() {
      return this.svg.width - 2 * this.svg.arrowWidth
    },
    svgIndicatorXOffset() {
      return this.svgLineWidth / (this.svg.indicatorCount + 1.0)
    },
    addModalTitle() {
      let title = 'Add'
      switch(this.currentTabIndex) {
        case 0:
          title += ' General Info'
          break;
        case 1:
          title += ' Medical History'
          break;
        case 2:
          title += ' Admission Note'
          break;
        case 3:
          title += ' Progress Note'
          break;
        case 4:
          title += ' Discharge Note'
          break;
        case 5:
          title += ' Inspection Report'
          break;
        case 6:
          title += 'Test Report'
          break;
        case 7:
          title += ' Surgery Report'
          break;
        default:
          // code block
      }
      return title;
    }
  },
  async mounted() {
  },
  methods:{
    svgIndicatorPoints(index) {
      const xOffset = (this.svg.arrowWidth + (index + 1) * this.svgIndicatorXOffset)
      const halfWidth = (this.svg.indicatorWidth / 2.0)
      const bottom = this.svg.indicatorMarginTop + this.svg.indicatorWidth
      return "" + xOffset + " " + this.svg.indicatorMarginTop + ", " + (xOffset + halfWidth) + " " + bottom + ", " + (xOffset - halfWidth) + " " + bottom
    },
    handleTabChange(tabIndex, newTab, oldTab) {
      // console.log('tabIndex:', tabIndex)
      // console.log('newTab:', newTab)
      // console.log('oldTab:', oldTab)

      this.svgStartDate = null
      this.svgEndDate = null
      this.svgIndicator = null
      this.currentTabIndex = tabIndex

      if (tabIndex == 0) {
        this.$refs.GeneralInfoList.getInfos()
      } else if (tabIndex == 1) {
        this.$refs.MedicalHistoryList.getInfos()
      } else if (tabIndex == 2) {
        this.$refs.AdmissionNoteList.getInfos()
      } else if (tabIndex == 3) {
        this.$refs.ProgressNoteList.getInfos()
      } else if (tabIndex == 4) {
        this.$refs.DischargeNoteList.getInfos() 
      } else if (tabIndex == 5) {
        this.$refs.InspectionReportList.getInfos()
      } else if (tabIndex == 6) {
        this.$refs.TestReportList.getInfos() 
      } else if (tabIndex == 7) {
        this.$refs.SurgeryReportList.getInfos()
      } 
    },
    back() {
      this.$emit('back')
    },
    share() {

    },
    svgLeftArrowClicked() {
      switch(this.currentTabIndex) {
        case 0:
          this.$refs.GeneralInfoList.timelinePrevious()
          break;
        case 1:
          this.$refs.MedicalHistoryList.timelinePrevious()
          break;
        case 2:
          this.$refs.AdmissionNoteList.timelinePrevious()
          break;
        case 3:
          this.$refs.ProgressNoteList.timelinePrevious()
          break;
        case 4:
          this.$refs.DischargeNoteList.timelinePrevious()
          break;
        case 5:
          this.$refs.InspectionReportList.timelinePrevious()
          break;
        case 6:
          this.$refs.TestReportList.timelinePrevious()
          break;
        case 7:
          this.$refs.SurgeryReportList.timelinePrevious()
          break;
        default:
          // code block
      }
    },
    svgRightArrowClicked() {
      switch(this.currentTabIndex) {
        case 0:
          this.$refs.GeneralInfoList.timelineNext()
          break;
        case 1:
          this.$refs.MedicalHistoryList.timelineNext()
          break;
        case 2:
          this.$refs.AdmissionNoteList.timelineNext()
          break;
        case 3:
          this.$refs.ProgressNoteList.timelineNext()
          break;
        case 4:
          this.$refs.DischargeNoteList.timelineNext()
          break;
        case 5:
          this.$refs.InspectionReportList.timelineNext()
          break;
        case 6:
          this.$refs.TestReportList.timelineNext()
          break;
        case 7:
          this.$refs.SurgeryReportList.timelineNext()
          break;
        default:
          // code block
      }
    },
    mouseover() {
      this.isHover = true
    },
    mouseout() {
      this.isHover = false
    },
    timelineInfo(param) {
      // console.log('timelineInfo param:', param)
      this.svgStartDate = param.startDate
      this.svgEndDate = param.endDate

      this.svgIndicator = param.timelineIndex
    }
  }
}
</script>
