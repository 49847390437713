<template>
  <div v-if="group">
    <div class='row'>
      <div class="col-md-12">
        <div class="float-right">
          <button type="submit" @click="save()" class="btn btn-fill btn-primary ml-3">Save</button>
          <button type="button" @click="cancel()" class="btn btn-outline ml-3">Cancel</button>
        </div>
      </div>
    </div>

    <div>
      <div class="row">
        <div :class="valueColumnClass">
          <fg-input
            type="text"
            name="name"
            data-vv-as="Group Name"
            class=''
            v-model="group.name"
            v-validate="{ required: true }"
            :error="getError('name')"
            />
        </div>
      </div>
      <h5>{{group.hospital}}</h5>
    </div>

    <div class="row">
      <div class="col-md-2 d-flex align-items-center mb-3">
        <fg-input
          type="number"
          name="dataSharingRate"
          data-vv-as="Data Sharing Rate"
          class='my-auto mr-1'
          v-model="group.dataSharingRate"
          v-validate="{ required: true }"
          :error="getError('dataSharingRate')"
          />%
      </div>
    </div>
    <div class="d-flex align-items-center">
      <div class="mr-2 my-auto">
        <p class="my-auto"><b>Status:</b></p>
      </div>
      <l-switch class="my-auto" v-model="group.status" on-text="ON" off-text="OFF"></l-switch>
      <!-- <div>
        <el-select class="select-default mb-3" v-model="statusModel" :close-transition="true">
          <el-option value='0' label="OFF" />
          <el-option value='1' label="ON" />
        </el-select>
      </div> -->
    </div>

    <div class="table-container">
      <div class="row">
        <!-- @row-clicked="test" -->
        <!-- @input-change="handleInput" -->
        <b-editable-table
          bordered
          class="editable-table col-12"
          v-model="group.users"
          :fields="fields"
          @input-change="handleInput"
        >
          <template #cell(isAdmin)="data">
            <BIconCheck v-if="isAdminWith(data.id)"
                class="edit-icon mx-auto" style="pointer-events: none;"
            ></BIconCheck>
            <div v-else type="button" class='btn-info btn-round btn-fill small mx-auto' @click="handleSetAdmin(data)">set</div>
          </template>
        </b-editable-table>
      </div>
    </div>

    

    
    
  </div>
</template>
<script>
  import { Dialog, Table, TableColumn, MessageBox, Select, Option, DatePicker, Tag, Autocomplete } from 'element-ui'
  import { Pagination as LPagination, Checkbox as LCheckbox, FadeRenderTransition, Switch as LSwitch } from 'src/components/index'
  import Card from '../../../components/Cards/Card.vue'
  import sweetAlert from 'sweetalert2/dist/sweetalert2.js'
  import lib from '../../../lib.js'
  import Vue from "vue"
  import BEditableTable from "bootstrap-vue-editable-table";
  import {
    BIconTrash,
    BIconPencil,
    BIconX,
    BIconCheck,
    BButton,
  } from "bootstrap-vue";

  export default {
    props: ['group'],
    components: {
      BEditableTable,
      BIconX,
      BIconTrash,
      BIconPencil,
      BIconCheck,
      BButton,
      [DatePicker.name]: DatePicker,
      [Select.name]: Select,
      [Option.name]: Option,
      LCheckbox,
      FadeRenderTransition,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      LPagination,
      LSwitch
    },
    Card,
    data() {
      return {
        fields: [
          {
            key: "id",
            label: "No",
            type: "text",
            editable: false,
            class: "col-1",
          },
          {
            key: "displayName",
            label: "Name",
            type: "text",
            editable: false,
            class: "col-2"
          },
          {
            key: "hospital",
            label: "Office",
            type: "text",
            editable: false,
            class: "col-2",
            validate: this.validateName
          },
          {
            key: "department",
            label: "Department",
            type: "text",
            editable: false,
            class: "col-2",
            validate: this.validateName
          },
          {
            key: "email",
            label: "Email",
            type: "text",
            editable: false,
            class: "col-3",
            validate: this.validateName
          },
          {
            key: "isAdmin",
            label: "Admin",
            type: "checkbox",
            editable: false,
            class: "col-1 py-0 text-center"
          }
        ],
        items: [],
        rowUpdate: {},
        
        pagination: {
          perPage: 20,
          currentPage: 1,
          total: 0
        },
        PaginationToken: null,
        titleColumnClass: 'col-md-3',
        valueColumnClass: 'col-md-9',
        showAlert: false,
        newPassword: null,
        confirmNewPassword: null
      }
    },
    beforeMount() {
    },
    async mounted () {
      for (var user of this.group.users) {
        user.isAdmin = (user.id == this.group.id)
      }
      console.log('group:', this.group)
    },
    computed: {
      pagedData () {
        return this.group.users
      },
      statusModel: {
        get: function() {
          return this.getStatusText(this.group.status)
        },
        set: function(newValue) {
          console.log('newValue:', newValue)
          switch (newValue) {
            case '0':
              this.group.status = false
              break
            case '1':
              this.group.status = true
              break
            default:
              break
          }
          console.log('this.group:', this.group)
        }
      },
    },
    methods: {
      handleSetAdmin(data) {
        // console.log('data.item:', data.item)
        this.group.admin = data.item.id
        this.group.adminId = data.item.id
      },
      handleInput(value, data) {
        console.log('handleInput value:', value)
        console.log('handleInput data:', data)

        const index = value.index
        const value2 = value.value
        console.log('index:', index)
        console.log('value:', value2)
      },
      getStatusText(value) {
        if (value) {
          return "ON"
        } else {
          return "OFF"
        }
      },
      hasItem() {
        return this.group.users.length != 0
      },
      handleDelete(data) {
        sweetAlert.fire({
          type: 'warning',
          title: 'Remove',
          text: data.item.displayName,
          confirmButtonText: 'Remove',
          cancelButtonText: 'Cancel',
          showCancelButton: true
        }).then((result) => {
          if (result.value) {
            this.deleteMember(data.id)
          }
        })
      },
      handleAdd() {
        this.$emit('groupMemberAdd', this.group)
      },
      cancel() {
        this.$emit('back')
      },
      save() {
        this.updateGroup()
      },
      async updateGroup() {
        const result = await lib.updateGroup(this.group.id, this.group)
        if (result.status == Vue.Constants.HttpStatus.Success) {
          sweetAlert.fire({
            type: 'success',
            text: result.data.message
          }).then((result) => {
            this.$emit('back')
          })
        } else {
          if (result.data.message) {
            sweetAlert.fire({
              type: 'error',
              text: result.data.message
            })
          }
        }
      },
      getGenderText(value) {
        switch (value) {
          case 0:
            return "Unknown"
          case 1:
            return "Male"
          case 2:
            return "Female"
          default:
            return ""
        }
      },
      getBirthdayText(value) {
        const date = new Date(value)
        return this.formatDate(date)
      },
      formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;

        return [year, month, day].join('/');
      },
      getAdminText(value) {
        return (value.admin) ? 'YES' : 'NO'
      },
      getDataSharingRateText(value) {
        return value.dataSharingRate.toString() + "%"
      },
      getError(fieldName) {
        return this.errors.first(fieldName)
      },
      back() {
        this.$emit('back')
      },
      isAdminWith(userId) {
        return this.group.admin == userId
      },
      validate() {
        var _this = this
        this.$validator.validateAll().then(isValid => {
          if (!isValid) return
        })
      },
    },
    beforeDestroy () {
    }
  }
</script>
