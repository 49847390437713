<template>
  <div v-if="hospital">
    <div class='row'>
      <div class="col-md-12">
        <div class="float-right">
          <button type="submit" @click="edit()" class="btn btn-fill btn-primary ml-3">Edit</button>
          <button type="button" @click="cancel()" class="btn btn-outline ml-3">Back</button>
        </div>
      </div>
    </div>

    <div>
      <h2><b>{{hospital.name}}</b></h2>
    </div>

    <div>
      <!-- <h3><pre>{{hospital.notice}}</pre></h3> -->
      <el-input class="input-disabled-text-black" type="textarea" name="notice" v-model="hospital.notice" rows="6" data-vv-as="Hospital Notice" v-validate="'max:6800'" disabled />
    </div>

    <br />

    <h6>Group List</h6>

    <div class="row" v-if='hospital'>
      <div class="col-sm-12">
        <el-table :data="pagedData" @row-click="handleRowChange" :cell-style="cellStyle">

          <el-table-column prop="name" label="Group">
          </el-table-column>

          <el-table-column prop="dataSharingRate" :formatter="getDataSharingRateText" label="Data Sharing">
          </el-table-column>

          <!-- <el-table-column prop="id" :formatter="getMemberText" label="Members">
          </el-table-column> -->
          <el-table-column prop="memberCount" label="Members">
          </el-table-column>

          <el-table-column prop="admin" :formatter="getAdminText" label="Admin">
          </el-table-column>

          <el-table-column prop="status" :formatter="getStatusText" label="Status">
          </el-table-column>

        </el-table>
      </div>
      <!-- <div slot="footer" class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap mt-3">
        <div class="">
          <p class="card-category"></p>
        </div>
        <l-pagination class="pagination-no-border"
          v-model="pagination.currentPage"
          :isMore="(PaginationToken != null)"
          :per-page="pagination.perPage"
          :total="pagination.total"
          @loadMore="getData(true)"/>
      </div> -->
    </div>

    
    
  </div>
</template>
<script>
  import { Dialog, Table, TableColumn, MessageBox, Select, Option, DatePicker, Tag, Autocomplete } from 'element-ui'
  import { Pagination as LPagination, Checkbox as LCheckbox, FadeRenderTransition } from 'src/components/index'
  import Card from '../../../components/Cards/Card.vue'
  import sweetAlert from 'sweetalert2/dist/sweetalert2.js'
  import lib from '../../../lib.js'
  import Vue from "vue"

  export default {
    props: ['hospitalId'],
    components: {
      [DatePicker.name]: DatePicker,
      [Select.name]: Select,
      [Option.name]: Option,
      LCheckbox,
      FadeRenderTransition,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      LPagination
    },
    Card,
    data() {
      return {
        pagination: {
          perPage: 20,
          currentPage: 1,
          total: 0
        },
        PaginationToken: null,
        titleColumnClass: 'col-md-3',
        valueColumnClass: 'col-md-9',
        hospital: null,
        showAlert: false,
        newPassword: null,
        confirmNewPassword: null
      }
    },
    beforeMount() {
    },
    async mounted () {
      await this.getHospital()
    },
    computed: {
      pagedData () {
        return this.hospital.groups
      },
    },
    methods: {
      cellStyle(row, rowIndex) {
        return 'cursor: pointer;'
      },
      handleRowChange(row, event, column) {
        this.$emit('browseGroup', row.id)
      },
      cancel() {
        this.$emit('back')
      },
      edit() {
        this.$emit('edit', this.hospital)
      },
      async getHospital() {
        const result = await lib.getHospital(this.hospitalId)
        if (result.status == Vue.Constants.HttpStatus.Success) {
          this.hospital = result.data.data
        } else {
          if (result.data.message) {
            sweetAlert.fire({
              type: 'error',
              text: result.data.message
            })
          }
        }
      },
      getGenderText(value) {
        switch (value) {
          case 0:
            return "Unknown"
          case 1:
            return "Male"
          case 2:
            return "Female"
          default:
            return ""
        }
      },
      getBirthdayText(value) {
        const date = new Date(value)
        return this.formatDate(date)
      },
      formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;

        return [year, month, day].join('/');
      },
      getStatusText(value) {
        return (value.status) ? 'ON' : 'OFF'
      },
      getAdminText(value) {
        return (value.admin) ? 'YES' : 'NO'
      },
      getMemberText(value) {
        return "?"
      },
      getDataSharingRateText(value) {
        return value.dataSharingRate.toString() + "%"
      },
      getError(fieldName) {
        return this.errors.first(fieldName)
      },
      back() {
        this.$emit('back')
      },
      validate() {
        var _this = this
        this.$validator.validateAll().then(isValid => {
          if (!isValid) return

          _this.createHospital()
        })
      },
    },
    beforeDestroy () {
    }
  }
</script>
