<template>
  <div>
    <div align='right' class="mt-3" v-if='isEditing'>
      <l-button size="sm" type="primary" @click="save()" class="px-2">Save</l-button>
      <l-button size="sm" type="default" outline @click="cancel()" class="ml-3 px-2">Cancel</l-button>
    </div>
    <div align='right' class="mb-3" v-if='!isEditing'>
      <l-button size="sm" type="primary" @click="edit()" class="px-2">Edit</l-button>
      <l-button size="sm" type="default" outline @click="back()" class="ml-3 px-2">Back</l-button>
    </div>

    <div>
      <div class="row" v-if="form">
        <div class="col-6">
          <div class="row">
            <div class="text-right" :class="titleColumnClass">
              <p><b>Diseases</b></p>
            </div>
            <div :class="valueColumnClass">
              <fg-input
                type="text"
                name="disease"
                data-vv-as="Diseases"
                class='input-disabled-text-black'
                v-model="form.disease"
                :error="getError('disease')"
                :readonly="!isEditing" />
            </div>
          </div>

          <div class="row">
            <div class="text-right" :class="titleColumnClass">
              <p><b>Departments</b></p>
            </div>
            <div :class="valueColumnClass">
              <fg-input
                type="text"
                name="department"
                data-vv-as="Departments"
                class='input-disabled-text-black'
                v-model="form.department"
                :error="getError('department')"
                :readonly="!isEditing" />
            </div>
          </div>

          <div class="row">
            <div class="text-right" :class="titleColumnClass">
              <p><b>Personal</b></p>
            </div>
            <div :class="valueColumnClass">
              <el-input class="mb-3 input-disabled-text-black" type="textarea" name="personal" v-model="form.personal" rows="6"  data-vv-as="Personal"  v-validate="'required|max:6800'"  :disabled="!isEditing"></el-input>
            </div>
          </div>

          <div class="row">
            <div class="text-right" :class="titleColumnClass">
              <p><b>Past</b></p>
            </div>
            <div :class="valueColumnClass">
              <el-input class="mb-3 input-disabled-text-black" type="textarea" name="past" v-model="form.past" rows="6"  data-vv-as="Past"  v-validate="'required|max:6800'"  :disabled="!isEditing"></el-input>
            </div>
          </div>

          
        </div>
      </div>
    </div>

  </div>
</template>
<script>
 /* eslint-disable */ 


import lib from '../../../lib'
import Vue from "vue"


export default {
  props: ['data'],
  data () {
    return {
      isEditing: false,
      form: null,
      titleColumnClass: 'col-md-4',
      valueColumnClass: 'col-md-8',
    }
  },
  components: {
  },
  async mounted() {
    this.createForm()
  },
  methods:{
    createForm() {
      this.form = {}
      this.form.disease = this.data.disease
      this.form.department = this.data.department
      this.form.personal = this.data.personal
      this.form.past = this.data.past
    },
    // async getInfos(){
    //   var result = await lib.generalInfoListOfUser(this.data.user_id, this.pagination.currentPage - 1)
    //   if (result.status == Vue.Constants.HttpStatus.Success) {
    //     var data = result.data
    //     this.infos = data.data

    //     this.pagination.perPage = data.page_size
    //     this.pagination.currentPage=  data.page + 1
    //     this.pagination.total = data.total_size
    //   }
    // },
    getError(fieldName) {
      return this.errors.first(fieldName)
    },
    save() {
      this.isEditing = false
    },
    cancel() {
      this.createForm()
      this.isEditing = false
    },
    edit() {
      this.isEditing = true
    },
    back() {
      this.$emit('back')
    },
  }
}
</script>
