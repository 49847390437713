<template>
  <div class="table-container">
    <div class="d-flex">
      <h5 class="my-auto">Group</h5>
      <b-button variant="primary" @click="handleAdd()" class="my-auto mx-3">Add</b-button>
    </div>

    <div class="row">
      <!-- @row-clicked="test" -->
      <b-editable-table
        v-show="hasItem()"
        disableDefaultEdit
        :rowUpdate="rowUpdate"
        :editMode="'row'"
        bordered
        class="editable-table col-12"
        v-model="items"
        :fields="fields"
      >
        <template #cell(isActive)="data">
          <span v-if="data.value">Yes</span>
          <span v-else>No</span>
        </template>
        <template #cell(admin)="data">
          <span v-if="data.value">Yes</span>
          <span v-else>No</span>
        </template>
        <template #cell(status)="data">
          <l-switch class="my-0" v-if='data.isEdit' v-model="data.item.status" on-text="ON" off-text="OFF"></l-switch>
          <div v-else>
            <span v-if="data.value">On</span>
            <span v-else>Off</span>
          </div>
        </template>
        <template #cell(edit)="data">
          <div class="my-0 mx-auto" v-if="data.isEdit">
            <BIconX class="edit-icon" @click="handleSubmit(data, false)"></BIconX>
            <BIconCheck
              class="edit-icon"
              @click="handleSubmit(data, true)"
            ></BIconCheck>
          </div>
          <BIconPencil
            v-else
            class="edit-icon mx-auto"
            @click="handleEdit(data, true)"
          ></BIconPencil>
        </template>
        <template #cell(delete)="data">
          <BIconTrash
            class="remove-icon mx-auto"
            @click="handleDelete(data)"
          ></BIconTrash>
        </template>
      </b-editable-table>
    </div>
  </div>
</template>

<script>
import BEditableTable from "bootstrap-vue-editable-table"
import { Switch as LSwitch } from 'src/components/index'
import {
  BIconTrash,
  BIconPencil,
  BIconX,
  BIconCheck,
  BButton,
} from "bootstrap-vue";
export default {
  components: {
    BEditableTable,
    BIconX,
    BIconTrash,
    BIconPencil,
    BIconCheck,
    BButton,
    LSwitch
  },
  data() {
    return {
      fields: [
        {
          key: "name",
          label: "Group Name",
          type: "text",
          editable: true,
          placeholder: "Group Name",
          validate: this.validateName,
        },
        {
          key: "dataSharing",
          label: "Data Sharing(%)",
          type: "number",
          editable: true,
          placeholder: "Data Sharing",
          class: "dataRate-col"
        },
        {
          key: "members",
          label: "Members",
          type: "number",
          editable: false,
          placeholder: "Members",
          class: "col-1"
        },
        {
          key: "admin",
          label: "Admin",
          type: "checkbox",
          editable: false,
          class: "col-1",
        },
        {
          key: "status",
          label: "Status",
          // type: "checkbox",
          // editable: true,
          class: "status-col py-0",
        },
        { key: "edit", label: "Edit", class: "col-1 text-center" },
        { key: "delete", label: "Remove", class: "col-1 text-center" },
      ],
      items: [],
      rowUpdate: {},
    };
  },
  methods: {
    hasItem() {
      return this.items.length != 0
    },
    handleAdd() {
      const newId = Date.now();
      this.rowUpdate = {
        edit: true,
        id: newId,
        action: "add",
        data: {
          id: newId,
          // age: null,
          // name: "",
          // department: 1,
          // dateOfBirth: null,
          // isActive: false,
          name: '',
          dataSharing: 50,
          members: 0,
          admin: false,
          status: true
        },
      };
    },
    handleSubmit(data, update) {
      this.rowUpdate = {
        edit: false,
        id: data.id,
        action: update ? "update" : "cancel",
      };
    },
    handleEdit(data) {
      this.rowUpdate = { edit: true, id: data.id };
    },
    handleDelete(data) {
      this.rowUpdate = { id: data.id, action: "delete" };
    },
    validateName(value) {
      if (value === '') {
        return {
          valid: false,
          errorMessage: 'Please enter name'
        }
      }
      return {valid: true};
    }
  },
};
</script>

<style>
.table-container {
  margin: 10px;
}

table.editable-table {
  margin-top: 10px;
}

table.editable-table td {
  vertical-align: middle;
}

.editable-table .data-cell {
  padding: 8px;
  vertical-align: middle;
}

.editable-table .custom-checkbox {
  width: 50px;
}

.remove-icon {
  color: red;
  cursor: pointer;
  font-size: 20px;
}

.edit-icon {
  color: rgb(4, 83, 158);
  cursor: pointer;
  font-size: 20px;
}

.status-col {
  width: 100px;
}

.dataRate-col {
  width: 140px;
}
</style>