<template>

  <div v-if="group">

    <div class='row'>
      <div class="col-md-12">
        <div class="float-right">
          <button type="submit" @click="edit()" class="btn btn-fill btn-primary ml-3">Edit</button>
          <button type="button" @click="cancel()" class="btn btn-outline ml-3">Back</button>
        </div>
      </div>
    </div>

    <div>
      <h2><b>{{group.name}}</b></h2>
      <h5>{{group.hospital}}</h5>
      <h5>{{group.dataSharingRate}}%</h5>
      <h5>Status: {{getStatusText(group.status)}}</h5>
    </div>

    <br />

    <div class="table-container">
      <div class="d-flex justify-content-between align-items-center">
        <h5 class="my-auto">Members</h5>
        <b-button variant="primary" @click="handleAdd()" class="my-auto mx-3">Add</b-button>
      </div>

      <div class="row">
        <!-- @row-clicked="test" -->
        <b-editable-table
          v-show="hasItem()"
          disableDefaultEdit
          :rowUpdate="rowUpdate"
          :editMode="'row'"
          bordered
          class="editable-table col-12"
          v-model="group.users"
          :fields="fields"
        >
          <!-- <template #cell(isActive)="data">
            <span v-if="data.value">Yes</span>
            <span v-else>No</span>
          </template> -->
          <template #cell(admin)="data">
            <!-- <span v-if="data.value">Yes</span>
            <span v-else>No</span> -->
            <BIconCheck v-if="isAdminWith(data.id)"
                class="edit-icon mx-auto" style="pointer-events: none;" 
              ></BIconCheck>
          </template>
          <!-- <template #cell(status)="data">
            <span v-if="data.value">Yes</span>
            <span v-else>No</span>
          </template> -->
          
          <template #cell(delete)="data">
            <BIconTrash
              class="remove-icon mx-auto"
              @click="handleDelete(data)"
            ></BIconTrash>
          </template>
        </b-editable-table>
      </div>
    </div>

    

    
    
  </div>
</template>
<script>
  import { Dialog, Table, TableColumn, MessageBox, Select, Option, DatePicker, Tag, Autocomplete } from 'element-ui'
  import { Pagination as LPagination, Checkbox as LCheckbox, FadeRenderTransition } from 'src/components/index'
  import Card from '../../../components/Cards/Card.vue'
  import sweetAlert from 'sweetalert2/dist/sweetalert2.js'
  import lib from '../../../lib.js'
  import Vue from "vue"
  import BEditableTable from "bootstrap-vue-editable-table";
  import {
    BIconTrash,
    BIconPencil,
    BIconX,
    BIconCheck,
    BButton,
  } from "bootstrap-vue";

  export default {
    props: ['groupId'],
    components: {
      BEditableTable,
      BIconX,
      BIconTrash,
      BIconPencil,
      BIconCheck,
      BButton,
      [DatePicker.name]: DatePicker,
      [Select.name]: Select,
      [Option.name]: Option,
      LCheckbox,
      FadeRenderTransition,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      LPagination
    },
    Card,
    data() {
      return {
        fields: [
          {
            key: "id",
            label: "No",
            type: "text",
            editable: false,
            placeholder: "Group Name",
            class: "col-1"
          },
          {
            key: "displayName",
            label: "Name",
            type: "text",
            editable: false,
            placeholder: "Group Name",
            class: "col-2"
          },
          {
            key: "hospital",
            label: "Office",
            type: "text",
            editable: false,
            placeholder: "Group Name",
            class: "col-2",
            validate: this.validateName
          },
          {
            key: "department",
            label: "Department",
            type: "text",
            editable: false,
            placeholder: "Group Name",
            class: "col-2",
            validate: this.validateName
          },
          {
            key: "email",
            label: "Email",
            type: "text",
            editable: false,
            placeholder: "Group Name",
            class: "col-3",
            validate: this.validateName
          },
          {
            key: "admin",
            label: "Admin",
            type: "checkbox",
            editable: false,
            class: "col-1 text-center",
          },
          { key: "delete", label: "Remove", class: "col-1 text-center" },
        ],
        items: [],
        rowUpdate: {},
        
        pagination: {
          perPage: 20,
          currentPage: 1,
          total: 0
        },
        PaginationToken: null,
        titleColumnClass: 'col-md-3',
        valueColumnClass: 'col-md-9',
        group: null,
        showAlert: false,
        newPassword: null,
        confirmNewPassword: null
      }
    },
    beforeMount() {
    },
    async mounted () {
      console.log('groupId:', this.groupId)
      await this.getGroup()
    },
    computed: {
      pagedData () {
        return this.group.users
      },
    },
    methods: {
      async deleteMember(userId) {
        var result = await lib.deleteMember(this.group.id, userId)
        if (result.status == Vue.Constants.HttpStatus.Success) {
          var data = result.data
          
          await this.getGroup()
        } else {
          if (result.data.message) {
            sweetAlert.fire({
              type: 'error',
              text: result.data.message
            })
          }
        }
      },
      hasItem() {
        return this.group.users.length != 0
      },
      handleDelete(data) {
        sweetAlert.fire({
          type: 'warning',
          title: 'Remove',
          text: data.item.displayName,
          confirmButtonText: 'Remove',
          cancelButtonText: 'Cancel',
          showCancelButton: true
        }).then((result) => {
          if (result.value) {
            this.deleteMember(data.id)
          }
        })
      },
      handleAdd() {
        this.$emit('groupMemberAdd', this.group)
      },
      cancel() {
        this.$emit('back')
      },
      edit() {
        this.$emit('edit', this.group)
      },
      async getGroup() {
        const result = await lib.getGroup(this.groupId)
        if (result.status == Vue.Constants.HttpStatus.Success) {
          // console.log('result:', result)
          this.group = result.data.data
          console.log('this.group:', this.group)
          // sweetAlert.fire({
          //   type: 'success',
          //   text: result.data.message
          // }).then((result) => {
          //   this.$emit('back')
          // })
        } else {
          if (result.data.message) {
            sweetAlert.fire({
              type: 'error',
              text: result.data.message
            })
          }
        }
      },
      getGenderText(value) {
        switch (value) {
          case 0:
            return "Unknown"
          case 1:
            return "Male"
          case 2:
            return "Female"
          default:
            return ""
        }
      },
      getBirthdayText(value) {
        const date = new Date(value)
        return this.formatDate(date)
      },
      formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;

        return [year, month, day].join('/');
      },
      getStatusText(value) {
        return (value) ? 'ON' : 'OFF'
      },
      getAdminText(value) {
        return (value.admin) ? 'YES' : 'NO'
      },
      getMemberText(value) {
        return "?"
      },
      getDataSharingRateText(value) {
        return value.dataSharingRate.toString() + "%"
      },
      getError(fieldName) {
        return this.errors.first(fieldName)
      },
      back() {
        this.$emit('back')
      },
      isAdminWith(userId) {
        return this.group.admin == userId
      },
      validate() {
        var _this = this
        this.$validator.validateAll().then(isValid => {
          if (!isValid) return

          // _this.createHospital()
        })
      },
    },
    beforeDestroy () {
    }
  }
</script>
