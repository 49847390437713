<template>
  <div>
    <div align='right' class="mb-3" v-if='!isEditing'>
      <l-button size="sm" type="primary" @click="edit()" class="px-2">Edit</l-button>
      <l-button size="sm" type="default" outline @click="back()" class="ml-3 px-2">Back</l-button>
    </div>
    <div align='right' class="mb-3" v-if='isEditing'>
      <l-button size="sm" type="primary" @click="save()" class="px-2">Save</l-button>
      <l-button size="sm" type="default" outline @click="cancel()" class="ml-3 px-2">Cancel</l-button>
    </div>
    
    <div v-if='form'>
      <div class="row">
        <div class="col-6">

          <div class="row">
            <div class="text-right" :class="titleColumnClass">
              <p><b>Career</b></p>
            </div>
            <div :class="valueColumnClass">
              <fg-input
                :readonly="!isEditing"
                type="text"
                name="career"
                data-vv-as="Career"
                class='input-disabled-text-black'
                v-model="form.career"
                :error="getError('career')"/>
            </div>
          </div>

          <div class="row">
            <div class="text-right" :class="titleColumnClass">
              <p><b>Education</b></p>
            </div>
            <div :class="valueColumnClass">
              <fg-input
                :readonly="!isEditing"
                type="text"
                name="education"
                data-vv-as="Education"
                class='input-disabled-text-black'
                v-model="form.education"
                :error="getError('education')"/>
            </div>
          </div>

          <div class="row">
            <div class="text-right" :class="titleColumnClass">
              <p><b>Ethnicity</b></p>
            </div>
            <div :class="valueColumnClass">
              <fg-input
                :readonly="!isEditing"
                type="text"
                name="ethnicity"
                data-vv-as="Ethnicity"
                class='input-disabled-text-black'
                v-model="form.ethnicity"
                :error="getError('ethnicity')"/>
            </div>
          </div>

          <div class="row">
            <div class="text-right" :class="titleColumnClass">
              <p><b>Economic</b></p>
            </div>
            <div :class="valueColumnClass">
              <fg-input
                :readonly="!isEditing"
                type="text"
                name="economic"
                data-vv-as="Economic"
                class='input-disabled-text-black'
                v-model="form.economic"
                :error="getError('economic')"/>
            </div>
          </div>

          <div class="row">
            <div class="text-right" :class="titleColumnClass">
              <p><b>Contact History</b></p>
            </div>
            <div :class="valueColumnClass">
              <fg-input
                :readonly="!isEditing"
                type="text"
                name="contract_history"
                data-vv-as="Contact History"
                class='input-disabled-text-black'
                v-model="form.contract_history"
                :error="getError('contract_history')"/>
            </div>
          </div>

          <div class="row">
            <div class="text-right" :class="titleColumnClass">
              <p><b>Occupational History</b></p>
            </div>
            <div :class="valueColumnClass">
              <fg-input
                :readonly="!isEditing"
                type="text"
                name="occupational_history"
                data-vv-as="Occupational History"
                class='input-disabled-text-black'
                v-model="form.occupational_history"
                :error="getError('occupational_history')" />
            </div>
          </div>
        </div>

        <div class="col-6">
          <div class="row">
            <div class="text-right" :class="titleColumnClass">
              <p><b>Marriage</b></p>
            </div>
            <div :class="valueColumnClass">
              <fg-input
                :readonly="!isEditing"
                type="text"
                name="marriage"
                data-vv-as="Marriage"
                class='input-disabled-text-black'
                v-model="form.marriage"
                :error="getError('marriage')"/>
            </div>
          </div>

          <div class="row">
            <div class="text-right" :class="titleColumnClass">
              <p><b>Religion</b></p>
            </div>
            <div :class="valueColumnClass">
              <fg-input
                :readonly="!isEditing"
                type="text"
                name="religion"
                data-vv-as="Religion"
                class='input-disabled-text-black'
                v-model="form.religion"
                :error="getError('religion')"/>
            </div>
          </div>

          <div class="row">
            <div class="text-right" :class="titleColumnClass">
              <p><b>Spirituality</b></p>
            </div>
            <div :class="valueColumnClass">
              <fg-input
                :readonly="!isEditing"
                type="text"
                name="spirituality"
                data-vv-as="Spirituality"
                class='input-disabled-text-black'
                v-model="form.spirituality"
                :error="getError('spirituality')"/>
            </div>
          </div>

          <div class="row">
            <div class="text-right" :class="titleColumnClass">
              <p><b>Traval History</b></p>
            </div>
            <div :class="valueColumnClass">
              <fg-input
                :readonly="!isEditing"
                type="text"
                name="travalHistory"
                data-vv-as="Traval History"
                class='input-disabled-text-black'
                v-model="form.travel_history"
                :error="getError('travalHistory')"/>
            </div>
          </div>

          <div class="row">
            <div class="text-right" :class="titleColumnClass">
              <p><b>Ethnic Groups</b></p>
            </div>
            <div :class="valueColumnClass">
              <fg-input
                :readonly="!isEditing"
                type="text"
                name="ethnicGroups"
                data-vv-as="Ethnic Groups"
                class='input-disabled-text-black'
                v-model="form.ethnic_group"
                :error="getError('ethnicGroups')"/>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
 /* eslint-disable */ 


import lib from '../../../lib'
import Vue from "vue"


export default {
  props: ['data'],
  data () {
    return {
      isEditing: false,
      form: null,
      titleColumnClass: 'col-md-4',
      valueColumnClass: 'col-md-8',
    }
  },
  components: {
  },
  async mounted() {
    this.createForm()
  },
  methods:{
    // async getInfos(){
    //   var result = await lib.generalInfoListOfUser(this.data.user_id, this.pagination.currentPage - 1)
    //   if (result.status == Vue.Constants.HttpStatus.Success) {
    //     var data = result.data
    //     this.infos = data.data

    //     this.pagination.perPage = data.page_size
    //     this.pagination.currentPage=  data.page + 1
    //     this.pagination.total = data.total_size
    //   }
    // },
    createForm() {
      this.form = {}
      this.form.career = this.data.career
      this.form.marriage = this.data.marriage
      this.form.education = this.data.education
      this.form.religion = this.data.religion
      this.form.ethnicity = this.data.ethnicity
      this.form.spirituality = this.data.spirituality
      this.form.economic = this.data.economic
      this.form.travel_history = this.data.travel_history
      this.form.contract_history = this.data.contract_history
      this.form.ethnic_group = this.data.ethnic_group
      this.form.occupational_history = this.data.occupational_history
    },
    getError(fieldName) {
      return this.errors.first(fieldName)
    },
    edit() {
      this.isEditing = true
    },
    back() {
      this.$emit('back')
    },
    save() {
      this.isEditing = false
    },
    cancel() {
      this.createForm()
      this.isEditing = false
    },
  }
}
</script>
