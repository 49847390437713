<template>
  <div>
    <div align='right' class="mt-3" v-if='isEditing'>
      <l-button size="sm" type="primary" @click="save()" class="px-2">Save</l-button>
      <l-button size="sm" type="default" outline @click="cancel()" class="ml-3 px-2">Cancel</l-button>
    </div>
    <div align='right' class="mb-3" v-if='!isEditing'>
      <l-button size="sm" type="primary" @click="edit()" class="px-2">Edit</l-button>
      <l-button size="sm" type="default" outline @click="back()" class="ml-3 px-2">Back</l-button>
    </div>

    <div>
      <div class="row" v-if="form">
        <div class="col-12">
          <!-- <div class="row">
            <div class="text-right" :class="titleColumnClass">
              <p><b>Department</b></p>
            </div>
            <div :class="valueColumnClass">
              <el-input type="textarea" name="subjectiveData" v-model="form.s_part" rows="6"  data-vv-as="Subjective Data"  v-validate="'max:6800'"></el-input>
            </div>
          </div> -->

          <p class="mb-1"><b>Department</b></p>
          <fg-input
            type="text"
            name="division"
            data-vv-as="Department"
            class='input-disabled-text-black'
            v-model="form.division"
            :error="getError('division')"
            :readonly="!isEditing" />

          <p class="mb-1"><b>Attending Physician</b></p>
          <fg-input
            type="text"
            name="attending_physician"
            data-vv-as="Attending Physician"
            class='input-disabled-text-black'
            v-model="form.attending_physician"
            :error="getError('attending_physician')"
            :readonly="!isEditing" />

          <br />

          <p class="mb-1"><b>Admission Diagnosis</b></p>
          <el-input class="mb-3 input-disabled-text-black" type="textarea" name="admission_diag" v-model="form.admission_diag" rows="6" data-vv-as="Admission Diagnosis" v-validate="'max:6800'" :disabled="!isEditing" />

          <p class="mb-1"><b>Discharge Diagnosis</b></p>
          <el-input class="mb-3 input-disabled-text-black" type="textarea" name="discharge_diag" v-model="form.discharge_diag" rows="6" data-vv-as="Discharge Diagnosis" v-validate="'max:6800'" :disabled="!isEditing" />

          <p class="mb-1"><b>Main Description</b></p>
          <el-input class="mb-3 input-disabled-text-black" type="textarea" name="chief_complaint" v-model="form.chief_complaint" rows="6" data-vv-as="Main Description" v-validate="'max:6800'" :disabled="!isEditing" />

          <p class="mb-1"><b>Medical History</b></p>
          <el-input class="mb-3 input-disabled-text-black" type="textarea" name="history" v-model="form.history" rows="6" data-vv-as="Medical History" v-validate="'max:6800'" :disabled="!isEditing" />

          <p class="mb-1"><b>Physical Examination Findings</b></p>
          <el-input class="mb-3 input-disabled-text-black" type="textarea" name="physical_find" v-model="form.physical_find" rows="6" data-vv-as="Physical Examination Findings" v-validate="'max:6800'" :disabled="!isEditing" />

          <br />
          <p><b>General Examination</b></p>
          <hr />

          <p class="mb-1"><b>Blood</b></p>
          <el-input class="mb-3 input-disabled-text-black" type="textarea" name="lab_exam_blood" v-model="form.lab_exam_blood" rows="6" data-vv-as="Blood" v-validate="'max:6800'" :disabled="!isEditing" />

          <p class="mb-1"><b>Biochemical</b></p>
          <el-input class="mb-3 input-disabled-text-black" type="textarea" name="lab_exam_bio" v-model="form.lab_exam_bio" rows="6" data-vv-as="Biochemical" v-validate="'max:6800'" :disabled="!isEditing" />

          <p class="mb-1"><b>Tests(Microscopy/Serum)</b></p>
          <el-input class="mb-3 input-disabled-text-black" type="textarea" name="lab_exam_tests" v-model="form.lab_exam_tests" rows="6" data-vv-as="Tests(Microscopy/Serum)" v-validate="'max:6800'" :disabled="!isEditing" />

          <hr />

          <p class="mb-1"><b>Radiation Report</b></p>
          <el-input class="mb-3 input-disabled-text-black" type="textarea" name="rad_report" v-model="form.rad_report" rows="6" data-vv-as="Radiation Report" v-validate="'max:6800'" :disabled="!isEditing" />

          <p class="mb-1"><b>Special Examinations</b></p>
          <el-input class="mb-3 input-disabled-text-black" type="textarea" name="special_exam" v-model="form.special_exam" rows="6" data-vv-as="Special Examinations" v-validate="'max:6800'" :disabled="!isEditing" />

          <p class="mb-1"><b>Pathology Report</b></p>
          <el-input class="mb-3 input-disabled-text-black" type="textarea" name="patho_report" v-model="form.patho_report" rows="6" data-vv-as="Pathology Report" v-validate="'max:6800'" :disabled="!isEditing" />

          <p class="mb-1"><b>Other</b></p>
          <el-input class="mb-3 input-disabled-text-black" type="textarea" name="other" v-model="form.other" rows="6" data-vv-as="Other" v-validate="'max:6800'" :disabled="!isEditing" />

          <p class="mb-1"><b>Date, method and findings of surgery</b></p>
          <el-input class="mb-3 input-disabled-text-black" type="textarea" name="op_result" v-model="form.op_result" rows="6" data-vv-as="Date, method and findings of surgery" v-validate="'max:6800'" :disabled="!isEditing" />

          <p class="mb-1"><b>Hospitalization</b></p>
          <el-input class="mb-3 input-disabled-text-black" type="textarea" name="course_treat" v-model="form.course_treat" rows="6" data-vv-as="Hospitalization" v-validate="'max:6800'" :disabled="!isEditing" />

          <p class="mb-1"><b>Complications</b></p>
          <el-input class="mb-3 input-disabled-text-black" type="textarea" name="complications" v-model="form.complications" rows="6" data-vv-as="Complications" v-validate="'max:6800'" :disabled="!isEditing" />

          <p class="mb-1"><b>Status at discharge</b></p>
          <el-input class="mb-3 input-disabled-text-black" type="textarea" name="discharge_status" v-model="form.discharge_status" rows="6" data-vv-as="Status at discharge" v-validate="'max:6800'" :disabled="!isEditing" />

          <p class="mb-1"><b>Discharge instructions</b></p>
          <el-input class="mb-3 input-disabled-text-black" type="textarea" name="discharge_instruction" v-model="form.discharge_instruction" rows="6" data-vv-as="Discharge instructions" v-validate="'max:6800'" :disabled="!isEditing" />


          <!-- <div class="row mt-3">
            <div class="text-right" :class="titleColumnClass">
              <p><b>Objective Data</b></p>
            </div>
            <div :class="valueColumnClass">
              <el-input type="textarea" name="objectiveData" v-model="form.o_part" rows="6"  data-vv-as="Objective Data"  v-validate="'max:6800'"></el-input>
            </div>
          </div>

          <div class="row mt-3">
            <div class="text-right" :class="titleColumnClass">
              <p><b>Assessment</b></p>
            </div>
            <div :class="valueColumnClass">
              <el-input type="textarea" name="assessment" v-model="form.a_part" rows="6"  data-vv-as="Assessment"  v-validate="'max:6800'"></el-input>
            </div>
          </div>

          <div class="row mt-3">
            <div class="text-right" :class="titleColumnClass">
              <p><b>Plan</b></p>
            </div>
            <div :class="valueColumnClass">
              <el-input type="textarea" name="plan" v-model="form.p_part" rows="6"  data-vv-as="Plan"  v-validate="'max:6800'"></el-input>
            </div>
          </div> -->

          
        </div>
      </div>
    </div>

  </div>
</template>
<script>
 /* eslint-disable */ 


import lib from '../../../lib'
import Vue from "vue"


export default {
  props: ['data'],
  data () {
    return {
      isEditing: false,
      form: null,
      titleColumnClass: 'col-md-2',
      valueColumnClass: 'col-md-10',
    }
  },
  components: {
  },
  async mounted() {
    this.createForm()
  },
  methods: {
    createForm() {
      this.form = {}
      this.form.division = this.data.division
      this.form.attending_physician = this.data.attending_physician
      this.form.admission_diag = this.data.admission_diag
      this.form.discharge_diag = this.data.discharge_diag
      this.form.chief_complaint = this.data.chief_complaint
      this.form.history = this.data.history
      this.form.physical_find = this.data.physical_find
      this.form.lab_exam_blood = this.data.lab_exam_blood
      this.form.lab_exam_bio = this.data.lab_exam_bio
      this.form.lab_exam_tests = this.data.lab_exam_tests
      this.form.rad_report = this.data.rad_report
      this.form.special_exam = this.data.special_exam
      this.form.patho_report = this.data.patho_report
      this.form.other = this.data.other
      this.form.op_result = this.data.op_result
      this.form.course_treat = this.data.course_treat
      this.form.complications = this.data.complications
      this.form.discharge_status = this.data.discharge_status
      this.form.discharge_instruction = this.data.discharge_instruction
    },
    // async getInfos(){
    //   var result = await lib.generalInfoListOfUser(this.data.user_id, this.pagination.currentPage - 1)
    //   if (result.status == Vue.Constants.HttpStatus.Success) {
    //     var data = result.data
    //     this.infos = data.data

    //     this.pagination.perPage = data.page_size
    //     this.pagination.currentPage=  data.page + 1
    //     this.pagination.total = data.total_size
    //   }
    // },
    getError(fieldName) {
      return this.errors.first(fieldName)
    },
    save() {
      this.isEditing = false
    },
    cancel() {
      this.createForm()
      this.isEditing = false
    },
    edit() {
      this.isEditing = true
    },
    back() {
      this.$emit('back')
    },
  }
}
</script>
