<template>
  <div>
    <div>
      <div class="row">
        <div class="col-6">
          <div class="row">
            <div class="text-right" :class="titleColumnClass">
              <p><b>Career</b></p>
            </div>
            <div :class="valueColumnClass">
              <p>{{data.career}}</p>
            </div>
          </div>

          <div class="row">
            <div class="text-right" :class="titleColumnClass">
              <p><b>Education</b></p>
            </div>
            <div :class="valueColumnClass">
              <p>{{data.education}}</p>
            </div>
          </div>

          <div class="row">
            <div class="text-right" :class="titleColumnClass">
              <p><b>Ethnicity</b></p>
            </div>
            <div :class="valueColumnClass">
              <p>{{data.ethnicity}}</p>
            </div>
          </div>

          <div class="row">
            <div class="text-right" :class="titleColumnClass">
              <p><b>Economic</b></p>
            </div>
            <div :class="valueColumnClass">
              <p>{{data.economic}}</p>
            </div>
          </div>

          <div class="row">
            <div class="text-right" :class="titleColumnClass">
              <p><b>Contact History</b></p>
            </div>
            <div :class="valueColumnClass">
              <p>{{data.contract_history}}</p>
            </div>
          </div>

          <div class="row">
            <div class="text-right" :class="titleColumnClass">
              <p><b>Occupational History</b></p>
            </div>
            <div :class="valueColumnClass">
              <p>{{data.occupational_history}}</p>
            </div>
          </div>
        </div>

        <div class="col-6">
          <div class="row">
            <div class="text-right" :class="titleColumnClass">
              <p><b>Marriage</b></p>
            </div>
            <div :class="valueColumnClass">
              <p>{{data.marriage}}</p>
            </div>
          </div>

          <div class="row">
            <div class="text-right" :class="titleColumnClass">
              <p><b>Religion</b></p>
            </div>
            <div :class="valueColumnClass">
              <p>{{data.religion}}</p>
            </div>
          </div>

          <div class="row">
            <div class="text-right" :class="titleColumnClass">
              <p><b>Spirituality</b></p>
            </div>
            <div :class="valueColumnClass">
              <p>{{data.spirituality}}</p>
            </div>
          </div>

          <div class="row">
            <div class="text-right" :class="titleColumnClass">
              <p><b>Traval History</b></p>
            </div>
            <div :class="valueColumnClass">
              <p>{{data.travel_history}}</p>
            </div>
          </div>

          <div class="row">
            <div class="text-right" :class="titleColumnClass">
              <p><b>Ethnic Groups</b></p>
            </div>
            <div :class="valueColumnClass">
              <p>{{data.ethnic_group}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div align='right'>
      <l-button size="sm" type="default" outline @click="back()" class="px-2">Back</l-button>
    </div>
  </div>
</template>
<script>
 /* eslint-disable */ 


export default {
  props: ['data'],
  data () {
    return {
      titleColumnClass: 'col-md-4',
      valueColumnClass: 'col-md-8',
    }
  },
  components: {
  },
  async mounted() {
  },
  methods:{
    back() {
      this.$emit('back')
    },
  }
}
</script>
