<template>
  <div v-if="hospital">
    <div class='row mb-3'>
      <div class="col-md-12">
        <div class="float-right">
          <button type="submit" @click.prevent="validate" class="btn btn-fill btn-primary ml-3">Save</button>
          <button type="button" @click="cancel()" class="btn btn-outline ml-3">Cancel</button>
        </div>
      </div>
    </div>

    <div class="row">
      <div :class="valueColumnClass">
        <fg-input
          type="text"
          name="name"
          data-vv-as="Hospital Name"
          class=''
          v-model="hospital.name"
          v-validate="{ required: true }"
          :error="getError('name')"
          />
      </div>
    </div>

    <div class="row">
      <div :class="valueColumnClass">
        <el-input type="textarea" name="notice" v-model="hospital.notice" rows="6"  data-vv-as="Notice"  v-validate="'required|max:6800'"></el-input>
      </div>
    </div>

    <br />

    <HospitalEditGroupTable ref="groupTable" :groups='hospital.groups' :hospitalId='hospital.id' />

    
    
  </div>
</template>
<script>
  import { Dialog, Table, TableColumn, MessageBox, Select, Option, DatePicker, Tag, Autocomplete } from 'element-ui'
  import { Checkbox as LCheckbox, FadeRenderTransition } from 'src/components/index'
  import Card from '../../../components/Cards/Card.vue'
  import sweetAlert from 'sweetalert2/dist/sweetalert2.js'
  import lib from '../../../lib.js'
  import Vue from "vue"
  import HospitalEditGroupTable from '../Components/HospitalEditGroupTable'

  export default {
    props: ['hospital'],
    components: {
      [DatePicker.name]: DatePicker,
      [Select.name]: Select,
      [Option.name]: Option,
      LCheckbox,
      FadeRenderTransition,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      HospitalEditGroupTable: HospitalEditGroupTable
    },
    Card,
    data() {
      return {
        titleColumnClass: 'col-md-3',
        valueColumnClass: 'col-md-9',
        form: null,
        showAlert: false,
        newPassword: null,
        confirmNewPassword: null
      }
    },
    beforeMount() {
    },
    async mounted () {
      // console.log('userId:', this.userId)
      // await this.getUser()
      this.form = {}
    },
    computed: {
    },
    methods: {
      cancel() {
        this.$emit('back')
      },
      async updateHospital() {
        const result = await lib.updateHospital(this.hospital.id, this.hospital)
        if (result.status == Vue.Constants.HttpStatus.Success) {
          sweetAlert.fire({
            type: 'success',
            text: result.data.message
          }).then((result) => {
            this.$emit('back')
          })
        } else {
          if (result.data.message) {
            sweetAlert.fire({
              type: 'error',
              text: result.data.message
            })
          }
        }
      },
      getGenderText(value) {
        switch (value) {
          case 0:
            return "Unknown"
          case 1:
            return "Male"
          case 2:
            return "Female"
          default:
            return ""
        }
      },
      getBirthdayText(value) {
        const date = new Date(value)
        return this.formatDate(date)
      },
      formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;

        return [year, month, day].join('/');
      },
      getStatusText(value) {
        return (value) ? 'ON' : 'OFF'
      },
      getError(fieldName) {
        return this.errors.first(fieldName)
      },
      back() {
        this.$emit('back')
      },
      validate() {
        var _this = this
        this.$validator.validateAll().then(isValid => {
          if (!isValid) return

          const groups = this.$refs.groupTable.$data.items
          console.log('groups:', groups)
          var newGroups = []
          for (var group of groups) {
            console.log('group:', group)
            if ((typeof group.dataSharingRate) == "string") {
              newGroups.push({
                name: group.name,
                dataSharingRate: parseInt(group.dataSharingRate),
                status: group.status
              })
            } else {
              newGroups.push({
                name: group.name,
                dataSharingRate: group.dataSharingRate,
                status: group.status
              })
            }
          }
          // if (newGroups.length != 0) {
            this.hospital.groups = newGroups
          // }
          console.log('newGroups:', newGroups)
          _this.updateHospital()
        })
      }
    },
    beforeDestroy () {
    }
  }
</script>
