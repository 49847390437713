<template>
  <div>
    <div>
      <div class="row">
        <div class="col-12">
          <!-- <div class="row">
            <div class="text-right" :class="titleColumnClass">
              <p><b>Subjective Data</b></p>
            </div>
            <div :class="valueColumnClass">
              <pre>{{data.s_part}}</pre>
            </div>
          </div> -->

          <p><b>Department</b><br />{{data.division}}</p>
          <p><b>Attending Physician</b><br />{{data.attending_physician}}</p>

          <br />

          <p><b>Admission Diagnosis</b><br /><pre>{{data.admission_diag}}</pre></p>
          <p><b>Discharge Diagnosis</b><br /><pre>{{data.discharge_diag}}</pre></p>
          <p><b>Main Description</b><br /><pre>{{data.chief_complaint}}</pre></p>
          <p><b>Medical History</b><br /><pre>{{data.history}}</pre></p>
          <p><b>Physical Examination Findings</b><br /><pre>{{data.physical_find}}</pre></p>

          <br />
          <p><b>General Examination</b></p>
          <hr />

          <p><b>Blood</b><br /><pre>{{data.lab_exam_blood}}</pre></p>
          <p><b>Biochemical</b><br /><pre>{{data.lab_exam_bio}}</pre></p>
          <p><b>Tests(Microscopy/Serum)</b><br /><pre>{{data.lab_exam_tests}}</pre></p>

          <hr />

          <p><b>Radiation Report</b><br /><pre>{{data.rad_report}}</pre></p>
          <p><b>Special Examinations</b><br /><pre>{{data.special_exam}}</pre></p>
          <p><b>Pathology Report</b><br /><pre>{{data.patho_report}}</pre></p>
          <p><b>Other</b><br /><pre>{{data.other}}</pre></p>
          <p><b>Date, method and findings of surgery</b><br /><pre>{{data.op_result}}</pre></p>
          <p><b>Hospitalization</b><br /><pre>{{data.course_treat}}</pre></p>
          <p><b>Complications</b><br /><pre>{{data.complications}}</pre></p>
          <p><b>Status at discharge</b><br /><pre>{{data.discharge_status}}</pre></p>
          <p><b>Discharge instructions</b><br /><pre>{{data.discharge_instruction}}</pre></p>

          

          <!-- <div class="row">
            <div class="text-right" :class="titleColumnClass">
              <p><b>Assignment</b></p>
            </div>
            <div :class="valueColumnClass">
              <pre>{{data.a_part}}</pre>
            </div>
          </div>

          <div class="row">
            <div class="text-right" :class="titleColumnClass">
              <p><b>Plan</b></p>
            </div>
            <div :class="valueColumnClass">
              <pre>{{data.p_part}}</pre>
            </div>
          </div> -->

        </div>
      </div>
    </div>

    <div align='right'>
      <l-button size="sm" type="default" outline @click="back()" class="px-2">Back</l-button>
    </div>
  </div>
</template>
<script>
 /* eslint-disable */ 


export default {
  props: ['data'],
  data () {
    return {
      // titleColumnClass: 'col-md-2',
      // valueColumnClass: 'col-md-10',
    }
  },
  components: {
  },
  async mounted() {
  },
  methods:{
    back() {
      this.$emit('back')
    },
  }
}
</script>
