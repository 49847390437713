<template>
  <div>
    <div align='right' class="mt-3" v-if='isEditing'>
      <l-button size="sm" type="primary" @click="save()" class="px-2">Save</l-button>
      <l-button size="sm" type="default" outline @click="cancel()" class="ml-3 px-2">Cancel</l-button>
    </div>
    <div align='right' class="mb-3" v-if='!isEditing'>
      <l-button size="sm" type="primary" @click="edit()" class="px-2">Edit</l-button>
      <l-button size="sm" type="default" outline @click="back()" class="ml-3 px-2">Back</l-button>
    </div>

    <div v-if="form">
      <div class="row">
        <div class="col">
          <p class="mb-1"><b>Patient Source</b></p>
          <fg-input
            type="text"
            name="patient_source"
            data-vv-as="Patient Source"
            class='input-disabled-text-black'
            v-model="form.patient_source"
            :error="getError('patient_source')"
            :readonly="!isEditing" />
        </div>
        <div class="col">
          <p class="mb-1"><b>Date of examination</b></p>
          <fg-input
            type="text"
            name="inspection_timestamp"
            data-vv-as="Date of examination"
            class='input-disabled-text-black'
            v-model="form.inspection_timestamp"
            :error="getError('inspection_timestamp')"
            :readonly="!isEditing" />
          </div>
      </div>
      <div class="row">
        <div class="col">
          <p class="mb-1"><b>Patient Bed Number</b></p>
          <fg-input
            type="text"
            name="bed_no"
            data-vv-as="Patient Bed Number"
            class='input-disabled-text-black'
            v-model="form.bed_no"
            :error="getError('bed_no')"
            :readonly="!isEditing" />
        </div>
        <div class="col">
          <p class="mb-1"><b>Date of receipt</b></p>
          <fg-input
            type="text"
            name="submit_timestamp"
            data-vv-as="Date of receipt"
            class='input-disabled-text-black'
            v-model="form.submit_timestamp"
            :error="getError('submit_timestamp')"
            :readonly="!isEditing" />
        </div>
      </div>
      <div class="row">
        <div class="col">
          <p class="mb-1"><b>Doctor（Medical order）</b></p>
          <fg-input
            type="text"
            name="order_doctor"
            data-vv-as="Doctor（Medical order）"
            class='input-disabled-text-black'
            v-model="form.order_doctor"
            :error="getError('order_doctor')"
            :readonly="!isEditing" />
        </div>
        <div class="col">
          <p class="mb-1"><b>Date of report</b></p>
          <fg-input
            type="text"
            name="report_timestamp"
            data-vv-as="Date of report"
            class='input-disabled-text-black'
            v-model="form.report_timestamp"
            :error="getError('report_timestamp')"
            :readonly="!isEditing" />
        </div>
      </div>
      <div class="row">
        <div class="col">
          <p class="mb-1"><b>Department</b></p>
          <fg-input
            type="text"
            name="division"
            data-vv-as="Department"
            class='input-disabled-text-black'
            v-model="form.division"
            :error="getError('division')"
            :readonly="!isEditing" />
        </div>
        <div class="col">
          <p class="mb-1"><b>Date of Specimen</b></p>
          <fg-input
            type="text"
            name="doctor_order_timestamp"
            data-vv-as="Date of Specimen"
            class='input-disabled-text-black'
            v-model="form.doctor_order_timestamp"
            :error="getError('doctor_order_timestamp')"
            :readonly="!isEditing" />
        </div>
      </div>
      <div class="row">
        <div class="col">
          <p class="mb-1"><b>Specimen Group</b></p>
          <fg-input
            type="text"
            name="test_group"
            data-vv-as="Specimen Group"
            class='input-disabled-text-black'
            v-model="form.test_group"
            :error="getError('test_group')"
            :readonly="!isEditing" />
        </div>
        <div class="col">
          <p class="mb-1"><b>Specimen Item</b></p>
          <fg-input
            type="text"
            name="specimen_type"
            data-vv-as="Specimen Item"
            class='input-disabled-text-black'
            v-model="form.specimen_type"
            :error="getError('specimen_type')"
            :readonly="!isEditing" />
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <p class="mb-1"><b>Specimen Description</b></p>
          <fg-input
            type="text"
            name="inspection_result_desc"
            data-vv-as="Specimen Description"
            class='input-disabled-text-black'
            v-model="form.inspection_result_desc"
            :error="getError('inspection_result_desc')"
            :readonly="!isEditing" />
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <p class="mb-1"><b>Daily urine volume</b></p>
          <fg-input
            type="text"
            name="urine_volume"
            data-vv-as="Daily urine volume"
            class='input-disabled-text-black'
            v-model="form.urine_volume"
            :error="getError('urine_volume')"
            :readonly="!isEditing" />
        </div>
      </div>
      <div class="row">
        <div class="col">
          <p class="mb-1"><b>White Blood Cell Screening</b></p>
          <fg-input
            type="text"
            name="white_blood_cell_screen"
            data-vv-as="White Blood Cell Screening"
            class='input-disabled-text-black'
            v-model="form.white_blood_cell_screen"
            :error="getError('white_blood_cell_screen')"
            :readonly="!isEditing" />
        </div>
      </div>
      <div class="row">
        <div class="col">
          <p class="mb-1"><b>Specimen Result Description</b></p>
          <el-input class="mb-3 input-disabled-text-black" type="textarea" name="inspection_result_block" v-model="form.inspection_result_block" rows="18" data-vv-as="Specimen Result Description" v-validate="'max:6800'" :disabled="!isEditing" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
 /* eslint-disable */ 


export default {
  props: ['data'],
  data () {
    return {
      isEditing: false,
      form: null,
    }
  },
  components: {
  },
  mounted() {
    this.createForm()
  },
  methods:{
    createForm() {
      this.form = {}
      this.form.patient_source = this.data.patient_source
      this.form.bed_no = this.data.bed_no
      this.form.order_doctor = this.data.order_doctor
      this.form.division = this.data.division
      this.form.test_group = this.data.test_group
      this.form.specimen_type = this.data.specimen_type
      this.form.urine_volume = this.data.urine_volume
      this.form.white_blood_cell_screen = this.data.white_blood_cell_screen
      this.form.inspection_result_desc = this.data.inspection_result_desc
      this.form.inspection_result_block = this.data.inspection_result_block
      this.form.inspection_timestamp = this.data.inspection_timestamp
      this.form.report_timestamp = this.data.report_timestamp
      this.form.doctor_order_timestamp = this.data.doctor_order_timestamp
      this.form.submit_timestamp = this.data.submit_timestamp
    },
    getError(fieldName) {
      return this.errors.first(fieldName)
    },
    save() {
      this.isEditing = false
    },
    cancel() {
      this.createForm()
      this.isEditing = false
    },
    edit() {
      this.isEditing = true
    },
    back() {
      this.$emit('back')
    }
  }
}
</script>
